import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CLFU9794SIMG from "../../../assets/Ceramics Triode(5-350KW)/FU9794S/FU9794S.jpg";
import DI1 from "../../../assets/Ceramics Triode(5-350KW)/FU9794S/img1.jpg";
import DI2 from "../../../assets/Ceramics Triode(5-350KW)/FU9794S/img2.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU9794S/img3.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU9794S/img4.jpg";
import Purchase from "../../../components/Purchase";

const CLFU9794S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CLFU9794SIMG} alt="" />
            <h2 className="text-xl">FU9794S Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU9794S is a thorium-tungsten cathode of the direct thermal
              carbonization,the matel-ceramic triode,for water-cooled,the
              maximum anode dissipation power is 80KW,the maximum operating
              frequency is 25MHz,the output power up to 170KW,The pipe used
              mainly for industrial high frequency heating device.can be instead
              the Japan 9T94A.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CLFU9794S;
