import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import P300BIMG from "../../../assets/Pentode/Pentode/300B/300B.jpg";
import DI from "../../../assets/Pentode/Pentode/300B/img1.jpg";
import TB1 from "../../../assets/Pentode/Pentode/300B/img2.jpg";
import TB2 from "../../../assets/Pentode/Pentode/300B/img3.jpg";
import Purchase from "../../../components/Purchase";

const P300B = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Pentode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={P300BIMG} alt="" />
            <h2 className="text-xl">300B Pentode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              Power in the 300B series directly heated cathode triode,A1 class
              amplifier applications,in the audio amplifier is sultable for in
              100W power output requirements. 300B-C long life design,selection
              of pure graphite anode and anode dissipation power can reach
              60W,appropriate adjustment circuit,single pipe as A1 class
              amplifier,more than 20W output power can be obtained.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default P300B;
