import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GT5868IMG from "../../../assets/Glass Triode(1-30KW)/5868/5868.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/5868/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/5868/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/5868/img3.jpg";
import Purchase from "../../../components/Purchase";

const GT5868 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GT5868IMG} alt="" />
            <h2 className="text-xl">5868 Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              5868 is a glass transistor of the direct thermal carbonization of
              thorium-tungsten cathode,anode cooling method for natural
              cooling,the maximum anode dissipation power is 450KW,the maximum
              operating frequency is 100MHz,the output power is 1690KW.the tubes
              are mainly used for amplification and oscillation with the anodic
              AM amplifiers and RF power amplifiers and oscillators.the tube can
              be used interchangrably.TB4/1250 or the United States 5868 tube.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GT5868;
