import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU834FASAIMG from "../../../assets/Ceramics Triode(5-350KW)/FU-834FASA/FU-834FASA.jpg";
import DI1 from "../../../assets/Ceramics Triode(5-350KW)/FU-834FASA/img1.jpg";
import DI2 from "../../../assets/Ceramics Triode(5-350KW)/FU-834FASA/img2.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU-834FASA/img3.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU-834FASA/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU834FASA = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU834FASAIMG} alt="" />
            <h2 className="text-xl">FU-834FA/SA Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              The FU-834FA/SA is a thorium-tungsten cathode,metal-ceramic
              triode,the anode cooling is water-cooled (SA) and forced air
              (FA),the maximum anode dissipation power is 15KW(SA) /10KW(FA),the
              maximum operating frequency is 60MHz,the output power is 30KW,the
              tube is mainly used for industrial high frequency heating
              equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU834FASA;
