import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import EVI from "../assets/homeev-removebg-preview.png";
import MI from "../assets/img4.png";
import CI from "../assets/capimg2.png";
import PGI from "../assets/img6.png";
import "./MainSlider.css";
import PVCM from "../assets/mold.png";
import BIMG from "../assets/Battries/battery.png";

export default function MainSlider() {
  const listRef = useRef(null);

  const showSlider = (type) => {
    const listHtml = listRef.current;
    const items = listHtml.querySelectorAll(".item");

    if (type === "next") {
      listHtml.appendChild(items[0].cloneNode(true));
      listHtml.removeChild(items[0]);
    } else if (type === "prev") {
      const lastItem = items[items.length - 1];
      listHtml.insertBefore(lastItem.cloneNode(true), items[0]);
      listHtml.removeChild(lastItem);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      showSlider("next");
    }, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="carousel ">
        <div className="list " ref={listRef}>
          <div className="item ">
            <img src={EVI} alt="" />
            <div className="introduce ">
              <div className="title">Electron Value</div>
              <div className="des">
                Discover top-of-the-line Electron Value designed for efficiency
                and precision. Elevate your production processes with our
                cutting-edge equipment, engineered to meet the demands of modern
                industries.
              </div>
              <Link to="/electronValue-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="item ">
            <img src={CI} alt="" />
            <div className="introduce">
              <div className="title">Capacitors</div>
              <div className="des">
                Discover top-of-the-line Capacitors designed for efficiency and
                precision. Elevate your production processes with our
                cutting-edge equipment, engineered to meet the demands of modern
                industries.
              </div>
              <Link to="/capacitor-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="item ">
            <img src={MI} alt="" />
            <div className="introduce">
              <div className="title">Machinery</div>
              <div className="des">
                Discover top-of-the-line industrial machinery designed for
                efficiency and precision. Elevate your production processes with
                our cutting-edge equipment, engineered to meet the demands of
                modern industries.
              </div>
              <Link to="/machinery-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="item ">
            <img src={PGI} alt="" />
            <div className="introduce">
              <div className="title">Printing Stuffs</div>
              <div className="des">
                Discover top-of-the-line Printing Stuffs designed for efficiency
                and precision. Elevate your production processes with our
                cutting-edge equipment, engineered to meet the demands of modern
                industries.
              </div>
              <Link to="/printingStuffs-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="item ">
            <img src={PVCM} alt="" />
            <div className="introduce">
              <div className="title">PVC Mold</div>
              <div className="des">
                Discover top-of-the-line PVC Mold designed for efficiency and
                precision. Elevate your production processes with our
                cutting-edge equipment, engineered to meet the demands of modern
                industries.
              </div>
              <Link to="/mold-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="item ">
            <img src={BIMG} alt="" />
            <div className="introduce">
              <div className="title">Battery</div>
              <div className="des">
                Discover top-of-the-line Battery designed for efficiency and
                precision. Elevate your production processes with our
                cutting-edge equipment, engineered to meet the demands of modern
                industries.
              </div>
              <Link to="/Battery-page">
                <button className="seeMore hover:text-blue-900">
                  See more <i className="fa-solid fa-location-arrow"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="arrows">
          <button
            className="prev hover:text-blue-900"
            onClick={() => showSlider("prev")}
          >
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <button
            className="next hover:text-blue-900"
            onClick={() => showSlider("next")}
          >
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </>
  );
}
