import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import PKT150IMG from "../../../assets/Pentode/Pentode/KT150/KT150.jpg";
import DI from "../../../assets/Pentode/Pentode/KT150/img1.jpg";
import TB1 from "../../../assets/Pentode/Pentode/KT150/img2.jpg";
import TB2 from "../../../assets/Pentode/Pentode/KT150/img3.jpg";
import Purchase from "../../../components/Purchase";

const PKT150 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Pentode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={PKT150IMG} alt="" />
            <h2 className="text-xl">KT150 Pentode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              KT150 is beam pentode power amplifier,the tube is mainly designed
              for audio purposes,anode dissipation power is 70,alternate
              push-pull amplifier A1 class. The tube design output power
              200W,the structural characteristics of decided to full load
              operation is stable and reliable.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default PKT150;
