import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU3092CAIMG from "../../../assets/Ceramics Triode(5-350KW)/FU3092CA/FU3092CA.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU3092CA/img1.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU3092CA/img2.jpg";
import TB3 from "../../../assets/Ceramics Triode(5-350KW)/FU3092CA/img3.jpg";
import Purchase from "../../../components/Purchase";

const CTFU3092CA = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU3092CAIMG} alt="" />
            <h2 className="text-xl">FU3092CA Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              Triode tube FU3092CA is a high power ceramic-metal triode; It has
              thoriated tungsten cathode and hyper-vapotron cooling of plate.
              The tube maximum plate dissipation is 120kW and maximum frequency
              operation is 30MHz. The tube designed for oscillator in the
              industry high frequency heating applications. The tube can replace
              YD1212 and ITK90-1.
            </p>
          </div>
          <br />
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU3092CA;
