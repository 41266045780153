import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU501IMG from "../../../assets/Glass Triode(1-30KW)/FU-501/FU-501.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/FU-501/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/FU-501/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/FU-501/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFU501 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU501IMG} alt="" />
            <h2 className="text-xl">FU-501 Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-501 is a direct thermal carbonization thorium-tungsten cathode
              glass trode anode cooling method by natural cooling,the maximum
              anode dissipation power is 450KW,the maximum operating frequency
              is 30MHz,the output power is 1500KW.the tube is mainly used for
              industrial high frequency heating equipment in for the oscillation
              and radio equipment for amplification.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU501;
