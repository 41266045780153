import React from "react";
import "./Purchasecss.css";

const Purchase = () => {
  return (
    <>
      {/* <div className="flex items-center justify-center bg-black text-white w-full h-16">
        <h1 id="PH1" className="text-center sm:text-end">
          For inquiries or to make a purchase of any of the listed items, kindly
          reach out to the provided contact details below.
        </h1>
      </div> */}
      <div className="bg-black h-16 flex items-center justify-center overflow-hidden">
        <div className=" moving-text" class="moving-text">
          For inquiries or to make a purchase of any of the listed items, kindly
          reach out to the provided contact details below.
        </div>
      </div>
    </>
  );
};

export default Purchase;
