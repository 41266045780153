import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GT6T40IMG from "../../../assets/Glass Triode(1-30KW)/6T40/6T40.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/6T40/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/6T40/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/6T40/img3.jpg";
import Purchase from "../../../components/Purchase";

const GT6T40 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GT6T40IMG} alt="" />
            <h2 className="text-xl">6T40 Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              6T40 is a direct thermal carbonization thorium-tungsten cathode
              glass triode anode cooling method by natural cooling,the maximum
              anode dissipation power is 500KW,the maximum operating frequency
              is 50MHz,the output power is 1.5KW. the pipes are mainly used for
              amplification with the oscillation and radio equipment for
              industrial high frequency heating equipment.Produced in Japan 6T40
              tube can be used interchangeably equivalent TB4/1500 type tube.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GT6T40;
