import React from "react";
import Footer from "../../components/Footer";
import Ca12 from "../../assets/capacitor/ca_watermark (1)_page-0012.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";
const Capacitor12 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Barrl-Styled High Voltage Low Frequency Capacitors CT87 & <br />
          Feed-Through Power RF-Capacitors(With Silicone Moisture Protection
          TGPA S)
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca12}
          alt="Barrl-Styled High Voltage Low Frequency Capacitors CT87 & Feed-Through Power RF-Capacitors(With Silicone Moisture
                  Protection TGPA S)"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor12;
