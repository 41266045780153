import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU8725FIMG from "../../../assets/Ceramics(2.5-35KW)/FU-8725F/FU-8725F.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/FU-8725F/img1.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU-8725F/img2.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU-8725F/img3.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/FU-8725F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU8725F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU8725FIMG} alt="" />
            <h2 className="text-xl">FU-8725F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-8725F is a thorium-tungsten cathode,the metal-ceramic
              triode,the anode cooling by forced air cooling,the maximum anode
              power dissipation for 15KW maximum operating frequency of
              40MHz,the output power of up 31KW the pipe used mainly for
              industrial in the frequency heating equipment.the tube can be
              produced in japan 8T25RA type of tube used interchangeably.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU8725F;
