import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import TTM5187FIMG from "../../../assets/Tetrode/Tetrode/TM5187F/TM5187F.jpg";
import DI from "../../../assets/Tetrode/Tetrode/TM5187F/img1.jpg";
import TB1 from "../../../assets/Tetrode/Tetrode/TM5187F/img2.jpg";
import TB2 from "../../../assets/Tetrode/Tetrode/TM5187F/img3.jpg";
import Purchase from "../../../components/Purchase";

const TTM5187F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Tetrode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={TTM5187FIMG} alt="" />
            <h2 className="text-xl">TM-5187F Tetrode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              TM-5187F is a direct thermal carbonization thorium-tungsten
              cathode and the metallic glass structure,high-voltage high-power
              pulsemodulation diode anodecooling by forced air,the maximum
              anodevoltage is 75KV,the maximum anode pulse current is 75A,the
              main tube with radar modulator for rigidmodulation pipe.the tube
              can instead France TH5187.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default TTM5187F;
