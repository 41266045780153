import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CT947FIMG from "../../../assets/Ceramics(2.5-35KW)/947F/947F.jpg";
import DI from "../../../assets/Ceramics(2.5-35KW)/947F/img1.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/947F/img2.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/947F/img3.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/947F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CT947F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CT947FIMG} alt="" />
            <h2 className="text-xl">947F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              947F is a direct thermal carbonization thorium-tungsten
              cathode,the metal-ceramic triode,the anode cooling by forced
              air,the maximum anode dissipation power is 2.5KW,the maximum
              operating frequency is 110MHz,the output power is 5.1KW.the tube
              is mainly used for industrial high frequency heating equipment.the
              tube can be instead for 7T85RB type tubes.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CT947F;
