import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import AVT845IMG from "../../../assets/Audio vacuum tube/845/845.jpg";
import DI from "../../../assets/Audio vacuum tube/845/img1.jpg";
import TB1 from "../../../assets/Audio vacuum tube/845/img2.jpg";
import TB2 from "../../../assets/Audio vacuum tube/845/img3.jpg";
import TB3 from "../../../assets/Audio vacuum tube/845/img4.jpg";
import TB4 from "../../../assets/Audio vacuum tube/845/img5.jpg";
import Purchase from "../../../components/Purchase";

const AVT845 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Audio Vacuum Tube</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={AVT845IMG} alt="" />
            <h2 className="text-xl">845 Audio Vacuum Tube</h2>
            <br />
            <p className="w-full lg:w-3/5">
              845 type vavle is directly heated glass shell structure type
              thorium tungsten carbide natural cooling cathode triode.the anode
              maximum disspation power is 100W. The valve is suitable for audio
              amplifier or amplitude modulator for low frequency power
              amplifier.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
            <img className="w-full lg:w-2/5" src={TB4} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default AVT845;
