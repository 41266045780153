import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU605IMG from "../../../assets/Glass Triode(1-30KW)/FU-605/FU-605.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/FU-605/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/FU-605/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/FU-605/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFU605 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU605IMG} alt="" />
            <h2 className="text-xl">FU-605 Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-605 is a direct thermal carbonization thorium-tungsten cathode
              glass triode anode cooling by natural cooling.the maximum anode
              dissipation power is 800W,the maximum operating frequency is
              50MHz,the output power is 2.5KW.Mainly used in industrial high
              frequency heating equipment,the tube can instead 6T51.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU605;
