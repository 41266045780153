import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFD934SIMG from "../../../assets/Glass Triode(30-600KW)/FD934S/FD934S.jpg";
import DI from "../../../assets/Glass Triode(30-600KW)/FD934S/img1.jpg";
import TB1 from "../../../assets/Glass Triode(30-600KW)/FD934S/img2.jpg";
import TB2 from "../../../assets/Glass Triode(30-600KW)/FD934S/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFD934S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(30-600KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFD934SIMG} alt="" />
            <h2 className="text-xl">FD934S Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FD934S is a glass metal triode,the anode cooling is water-cooled
              high-power direct heating thorium-tungsten cathode,maximum anode
              dissipation power is 120KW,maximum operating frequency is 6MHz,the
              output power is 200KW.The pipes are mainly used in industrial high
              frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFD934S;
