import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFD950SIMG from "../../../assets/Glass Triode(30-600KW)/FD-950S/FD-950S.jpg";
import DI from "../../../assets/Glass Triode(30-600KW)/FD-950S/img1.jpg";
import TB1 from "../../../assets/Glass Triode(30-600KW)/FD-950S/img2.jpg";
import TB2 from "../../../assets/Glass Triode(30-600KW)/FD-950S/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFD950S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(30-600KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFD950SIMG} alt="" />
            <h2 className="text-xl">FD-950S Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FD-950S is s direct thermal carbonization thorium-tungsten
              cathode,glass-metal transistor,water-cooled anode cooling,the
              maxinmum anode dissipation power is 220KW,the maximum
              operatingfrequency is 6MHZ,the output power up to 600KW,the pipe
              used mainly for industrial in the frequencyheating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFD950S;
