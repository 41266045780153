import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import MCK2091 from "../../assets/Magnetron/CK2091.jpg";

const Magnetron10 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Magnetron</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/MCK2091-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={MCK2091} alt="CK2091" />
                <h1>CK2091</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Magnetron10;
