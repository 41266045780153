import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import MainSlider from "./components/MainSlider";
import CapacitorPage from "./pages/CapacitorPage";
import ElectronValuePage from "./pages/ElectronValuePage";
import MachineryPage from "./pages/MachineryPage";
import PrintingStuffsPage from "./pages/PrintingStuffsPage";
import AudioVacuumTube5 from "./pages/ElectronValueProducts/AudioVacuumTube5";
import Ceramics3 from "./pages/ElectronValueProducts/CeramicsTriode3";
import CeramicsTriode4 from "./pages/ElectronValueProducts/CeramicsTriode4";
import GlassTriode1 from "./pages/ElectronValueProducts/GlassTriode1";
import GlassTriode2 from "./pages/ElectronValueProducts/GlassTriode2";
import Magnetron10 from "./pages/ElectronValueProducts/Magnetron10";
import Pentode7 from "./pages/ElectronValueProducts/Pentode7";
import Rectifier6 from "./pages/ElectronValueProducts/Rectifier6";
import Tetrode8 from "./pages/ElectronValueProducts/Tetrode8";
import Thyratron9 from "./pages/ElectronValueProducts/Thyratron9";
import Mold from "./pages/Mold";
import Capacitor1 from "./pages/CapacitorProducts/Capacitor1";
import Capacitor2 from "./pages/CapacitorProducts/Capacitor2";
import Capacitor3 from "./pages/CapacitorProducts/Capacitor3";
import Capacitor4 from "./pages/CapacitorProducts/Capacitor4";
import Capacitor5 from "./pages/CapacitorProducts/Capacitor5";
import Capacitor6 from "./pages/CapacitorProducts/Capacitor6";
import Capacitor7 from "./pages/CapacitorProducts/Capacitor7";
import Capacitor8 from "./pages/CapacitorProducts/Capacitor8";
import Capacitor9 from "./pages/CapacitorProducts/Capacitor9";
import Capacitor10 from "./pages/CapacitorProducts/Capacitor10";
import Capacitor11 from "./pages/CapacitorProducts/Capacitor11";
import Capacitor12 from "./pages/CapacitorProducts/Capacitor12";
import Capacitor13 from "./pages/CapacitorProducts/Capacitor13";
import Battery from "./pages/Battery";
import MCK2091 from "./pages/ElectronValueProducts/Magnetron/MCK2091";
import TNL5557 from "./pages/ElectronValueProducts/Thyratron/TNL5557";
import R577 from "./pages/ElectronValueProducts/Rectifier/R577";
import AVT211 from "./pages/ElectronValueProducts/AVT/AVT211";
import AVT212 from "./pages/ElectronValueProducts/AVT/AVT212";
import AVT845 from "./pages/ElectronValueProducts/AVT/AVT845";
import P300B from "./pages/ElectronValueProducts/Pentode/P300B";
import PKT88 from "./pages/ElectronValueProducts/Pentode/PKT88";
import PKT150 from "./pages/ElectronValueProducts/Pentode/PKT150";
import T4400C from "./pages/ElectronValueProducts/Tetrode/T4400C";
import TTM927F from "./pages/ElectronValueProducts/Tetrode/TTM927F";
import TTM5187F from "./pages/ElectronValueProducts/Tetrode/TTM5187F";
import GT572B from "./pages/ElectronValueProducts/GlassTriodeA/GT572B";
import GTFU200 from "./pages/ElectronValueProducts/GlassTriodeA/GTFU200";
import GT6T40 from "./pages/ElectronValueProducts/GlassTriodeA/GT6T40";
import GT3500Z from "./pages/ElectronValueProducts/GlassTriodeA/GT3500Z";
import GT5867A from "./pages/ElectronValueProducts/GlassTriodeA/GT5867A";
import GT5868 from "./pages/ElectronValueProducts/GlassTriodeA/GT5868";
import GT7092 from "./pages/ElectronValueProducts/GlassTriodeA/GT7092";
import GTFU10SZ from "./pages/ElectronValueProducts/GlassTriodeA/GTFU10SZ";
import GTFU33 from "./pages/ElectronValueProducts/GlassTriodeA/GTFU33";
import GTFU307S from "./pages/ElectronValueProducts/GlassTriodeA/GTFU307S";
import GTFU501 from "./pages/ElectronValueProducts/GlassTriodeA/GTFU501";
import GTFU605 from "./pages/ElectronValueProducts/GlassTriodeA/GTFU605";
import GTFU606 from "./pages/ElectronValueProducts/GlassTriodeA/GTFU606";
import GTFD934S from "./pages/ElectronValueProducts/GlassTriodeB/GTFD934S";
import GTFU431S from "./pages/ElectronValueProducts/GlassTriodeB/GTFU431S";
import GTFU308S from "./pages/ElectronValueProducts/GlassTriodeB/GTFU308S";
import GTFD911S from "./pages/ElectronValueProducts/GlassTriodeB/GTFD911S";
import GTFU433S from "./pages/ElectronValueProducts/GlassTriodeB/GTFU433S";
import GTFD912S from "./pages/ElectronValueProducts/GlassTriodeB/GTFD912S";
import GTFD935S from "./pages/ElectronValueProducts/GlassTriodeB/GTFD935S";
import GTFD950S from "./pages/ElectronValueProducts/GlassTriodeB/GTFD950S";
import CTFU3061F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU3061F";
import CTFU957F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU957F";
import CTFU959F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU959F";
import CTFU3062F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU3062F";
import CTFU998F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU998F";
import CT947F from "./pages/ElectronValueProducts/CeramicsTriodeA/CT947F";
import CT946F from "./pages/ElectronValueProducts/CeramicsTriodeA/CT946F";
import CTFU3069FFA from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU3069FFA";
import CTFU8785F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU8785F";
import CTFU3130F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU3130F";
import CTFU8725F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU8725F";
import CTFU8787F from "./pages/ElectronValueProducts/CeramicsTriodeA/CTFU8787F";
import CTFU3092CA from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU3092CA";
import CTFU824FFBSSB from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU824FFBSSB";
import CTFU834FASA from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU834FASA";
import CTFU836F from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU836F";
import CTFD911SA from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFD911SA";
import CTFD911SC from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFD911SC";
import CTFU958S from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU958S";
import CLFU9794S from "./pages/ElectronValueProducts/CeramicsTriodeB/CLFU9794S";
import CTFU954S from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU954S";
import CTFU912Z from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU912Z";
import CTFU955S from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU955S";
import CTFU956S from "./pages/ElectronValueProducts/CeramicsTriodeB/CTFU956S";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/main-slider" element={<MainSlider />} />
          <Route path="/capacitor-page" element={<CapacitorPage />} />
          <Route path="/machinery-page" element={<MachineryPage />} />
          <Route path="/electronValue-page" element={<ElectronValuePage />} />
          <Route path="/printingStuffs-page" element={<PrintingStuffsPage />} />
          <Route path="/GlassTriode-1-30KW" element={<GlassTriode1 />} />
          <Route path="/GlassTriode-30-600KW" element={<GlassTriode2 />} />
          <Route path="/Ceramics-2.5-35KW" element={<Ceramics3 />} />
          <Route path="/CeramicsTriode-5-350KW" element={<CeramicsTriode4 />} />
          <Route path="/AudioVacuumTube" element={<AudioVacuumTube5 />} />
          <Route path="/Rectifier" element={<Rectifier6 />} />
          <Route path="/Pentode" element={<Pentode7 />} />
          <Route path="/Tetrode" element={<Tetrode8 />} />
          <Route path="/Thyratron" element={<Thyratron9 />} />
          <Route path="/Magnetron" element={<Magnetron10 />} />
          <Route path="/mold-page" element={<Mold />} />
          <Route path="/Capacitor1-Page" element={<Capacitor1 />}></Route>
          <Route path="/Capacitor2-Page" element={<Capacitor2 />}></Route>
          <Route path="/Capacitor3-Page" element={<Capacitor3 />}></Route>
          <Route path="/Capacitor4-Page" element={<Capacitor4 />}></Route>
          <Route path="/Capacitor5-Page" element={<Capacitor5 />}></Route>
          <Route path="/Capacitor6-Page" element={<Capacitor6 />}></Route>
          <Route path="/Capacitor7-Page" element={<Capacitor7 />}></Route>
          <Route path="/Capacitor8-Page" element={<Capacitor8 />}></Route>
          <Route path="/Capacitor9-Page" element={<Capacitor9 />}></Route>
          <Route path="/Capacitor10-Page" element={<Capacitor10 />}></Route>
          <Route path="/Capacitor11-Page" element={<Capacitor11 />}></Route>
          <Route path="/Capacitor12-Page" element={<Capacitor12 />}></Route>
          <Route path="/Capacitor13-Page" element={<Capacitor13 />}></Route>
          <Route path="/Battery-page" element={<Battery />}></Route>
          <Route path="/MCK2091-Page" element={<MCK2091 />}></Route>
          <Route path="/TNL5557-Page" element={<TNL5557 />}></Route>
          <Route path="/R577-Page" element={<R577 />}></Route>
          <Route path="/AVT211-Page" element={<AVT211 />}></Route>
          <Route path="/AVT212-Page" element={<AVT212 />}></Route>
          <Route path="/AVT845-Page" element={<AVT845 />}></Route>
          <Route path="/P300B-Page" element={<P300B />}></Route>
          <Route path="/PKT88-Page" element={<PKT88 />}></Route>
          <Route path="/PKT150-Page" element={<PKT150 />}></Route>
          <Route path="/T4400C-Page" element={<T4400C />}></Route>
          <Route path="/TTM927F-Page" element={<TTM927F />}></Route>
          <Route path="/TTM5187F-Page" element={<TTM5187F />}></Route>
          <Route path="/572B-Page" element={<GT572B />}></Route>
          <Route path="/FU200-Page" element={<GTFU200 />}></Route>
          <Route path="/6T40-Page" element={<GT6T40 />}></Route>
          <Route path="/3500Z-Page" element={<GT3500Z />}></Route>
          <Route path="/5867A-Page" element={<GT5867A />}></Route>
          <Route path="/5868-Page" element={<GT5868 />}></Route>
          <Route path="/7092-Page" element={<GT7092 />}></Route>
          <Route path="/FU10SZ-Page" element={<GTFU10SZ />}></Route>
          <Route path="/FU33-Page" element={<GTFU33 />}></Route>
          <Route path="/FU307S-Page" element={<GTFU307S />}></Route>
          <Route path="/FU501-Page" element={<GTFU501 />}></Route>
          <Route path="/FU605-Page" element={<GTFU605 />}></Route>
          <Route path="/FU606-Page" element={<GTFU606 />}></Route>
          <Route path="/FD934S-Page" element={<GTFD934S />}></Route>
          <Route path="/FU431S-Page" element={<GTFU431S />}></Route>
          <Route path="/FU308S-Page" element={<GTFU308S />}></Route>
          <Route path="/FD911S-Page" element={<GTFD911S />}></Route>
          <Route path="/FU433S-Page" element={<GTFU433S />}></Route>
          <Route path="/FD912S-Page" element={<GTFD912S />}></Route>
          <Route path="/FD935S-Page" element={<GTFD935S />}></Route>
          <Route path="/FD950S-Page" element={<GTFD950S />}></Route>
          <Route path="/FU3061F-Page" element={<CTFU3061F />}></Route>
          <Route path="/FU957F-Page" element={<CTFU957F />}></Route>
          <Route path="/FU959F-Page" element={<CTFU959F />}></Route>
          <Route path="/FU3062F-Page" element={<CTFU3062F />}></Route>
          <Route path="/FU998F-Page" element={<CTFU998F />}></Route>
          <Route path="/947F-Page" element={<CT947F />}></Route>
          <Route path="/946F-Page" element={<CT946F />}></Route>
          <Route path="/FU3069FFA-Page" element={<CTFU3069FFA />}></Route>
          <Route path="/FU8785F-Page" element={<CTFU8785F />}></Route>
          <Route path="/FU3130F-Page" element={<CTFU3130F />}></Route>
          <Route path="/FU8725F-Page" element={<CTFU8725F />}></Route>
          <Route path="/FU8787F-Page" element={<CTFU8787F />}></Route>
          <Route path="/FU3092CA-Page" element={<CTFU3092CA />}></Route>
          <Route path="/FU824FFBSSB-Page" element={<CTFU824FFBSSB />}></Route>
          <Route path="/FU834FASA-Page" element={<CTFU834FASA />}></Route>
          <Route path="/FU836F-Page" element={<CTFU836F />}></Route>
          <Route path="/FD911SA-Page" element={<CTFD911SA />}></Route>
          <Route path="/FD911SC-Page" element={<CTFD911SC />}></Route>
          <Route path="/FU958S-Page" element={<CTFU958S />}></Route>
          <Route path="/FU9794S-Page" element={<CLFU9794S />}></Route>
          <Route path="/FU954S-Page" element={<CTFU954S />}></Route>
          <Route path="/FU912Z-Page" element={<CTFU912Z />}></Route>
          <Route path="/FU955S-Page" element={<CTFU955S />}></Route>
          <Route path="/FU956S-Page" element={<CTFU956S />}></Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
