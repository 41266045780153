import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import Purchase from "../components/Purchase.jsx";
import C1 from "../assets/capacitor/ca_watermark (1)_page-0001.jpg";
import C2 from "../assets/capacitor/ca_watermark (1)_page-0002.jpg";
import C3 from "../assets/capacitor/ca_watermark (1)_page-0003.jpg";
import C4 from "../assets/capacitor/ca_watermark (1)_page-0004.jpg";
import C5 from "../assets/capacitor/ca_watermark (1)_page-0005.jpg";
import C6 from "../assets/capacitor/ca_watermark (1)_page-0006.jpg";
import C7 from "../assets/capacitor/ca_watermark (1)_page-0007.jpg";
import C8 from "../assets/capacitor/ca_watermark (1)_page-0008.jpg";
import C9 from "../assets/capacitor/ca_watermark (1)_page-0009.jpg";
import C10 from "../assets/capacitor/ca_watermark (1)_page-0010.jpg";
import C11 from "../assets/capacitor/ca_watermark (1)_page-0011.jpg";
import C12 from "../assets/capacitor/ca_watermark (1)_page-0012.jpg";
import C13 from "../assets/capacitor/ca_watermark (1)_page-0013.jpg";
import SampleNavbar from "../components/SampleNavbar.jsx";

const CapacitorPage = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">High Power Capacitors</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/Capacitor1-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold ">
                <img
                  className="w-72 h-80 image-container "
                  src={C1}
                  alt="Disc/Plate Power RF-Capacitors CCG81"
                />
                <h1 className="text-xs">
                  Disc/Plate Power RF-Capacitors CCG81
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor2-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C2}
                  alt="Plate Power RF-Capacitors With Ceramic Base CCG8"
                />
                <h1 className="text-xs">
                  Plate Power RF-Capacitors With Ceramic Base CCG8
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor3-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C3}
                  alt="Watercooled Power RF-Capacitors(External Cooling)"
                />
                <h1 className="text-xs">
                  Watercooled Power RF-Capacitors(External Cooling)
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor4-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C4}
                  alt="Watercooled Power RF-Capacitors(Internal Cooling) Contoured rim protected with silicone rubber"
                />
                <h1 className="text-xs">
                  Watercooled Power RF-Capacitors(Internal Cooling) Contoured
                  rim protected with silicone rubber
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor5-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C5}
                  alt="Tubular Power RF-Capacitors CCG5"
                />
                <h1 className="text-xs">Tubular Power RF-Capacitors CCG5</h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor6-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C6}
                  alt="Bottle(Pot) Power RF-Capacitor(CCG11 & CCG10A)"
                />
                <h1 className="text-xs">
                  Bottle(Pot) Power RF-Capacitor(CCG11 & CCG10A)
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor7-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C7}
                  alt="Pot Power RF-Capacitors and Rim"
                />
                <h1 className="text-xs">Pot Power RF-Capacitors & Rim</h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor8-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C8}
                  alt="Tube-Style Power RF-Capacitors CCG20"
                />
                <h1 className="text-xs">
                  Tube-Style Power RF-Capacitors CCG20
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor9-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C9}
                  alt="Feed-Through Power RF-Capacitors"
                />
                <h1 className="text-xs">Feed-Through Power RF-Capacitors</h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor10-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C10}
                  alt="Feed-Through High Voltage Capacitors CCG51 / Plate High Voltage Capacitors with Contoured Rim PS55"
                />
                <h1 className="text-xs">
                  Feed-Through High Voltage Capacitors CCG51
                </h1>
                <h1 className="text-xs">
                  Plate High Voltage Capacitors with Contoured Rim PS55
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor11-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C11}
                  alt="Barrl-Styled Power RF-Capacitors(CCG6, CCG61 & TOS)"
                />
                <h1 className="text-xs">
                  Barrl-Styled Power RF-Capacitors(CCG6, CCG61 & TOS)
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor12-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C12}
                  alt="Barrl-Styled High Voltage Low Frequency Capacitors CT87 / Feed-Through Power RF-Capacitors(With Silicone Moisture Protection TGPA S)"
                />
                <h1 className="text-xs">
                  Barrl-Styled High Voltage Low Frequency Capacitors CT87
                </h1>
                <h1 className="text-xs">
                  Feed-Through Power RF-Capacitors(With Silicone Moisture
                  Protection TGPA S)
                </h1>
              </div>
            </button>
          </Link>
          <Link to="/Capacitor13-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-80 h-96 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-72 h-80"
                  src={C13}
                  alt="Disc/DC Blocking Power RF-Capacitors / High Frequency And Low Inductance Capacitors(CBB90B C 500 T) "
                />

                <h1 className="text-xs">
                  Disc/DC Blocking Power RF-Capacitors
                </h1>
                <h1 className="text-xs">
                  High Frequency And Low Inductance Capacitors(CBB90B C 500 T)
                </h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CapacitorPage;
