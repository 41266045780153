import React from "react";
import Footer from "../components/Footer.jsx";
import MoldImg from "../assets/mould.jpg";
import Purchase from "../components/Purchase.jsx";
import SampleNavbar from "../components/SampleNavbar.jsx";

const Mold = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="flex mb-5">
          <h1 className="text-3xl text-center">PVC Mould</h1>
        </div>
        <img className="w-11/12 lg:w-1/2" src={MoldImg} alt="" />
      </div>

      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Mold;
