import React from "react";
import Footer from "../../components/Footer";
import Ca13 from "../../assets/capacitor/ca_watermark (1)_page-0013.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";
const Capacitor13 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Disc/DC Blocking Power RF-Capacitors &<br /> High Frequency And Low
          Inductance Capacitors(CBB90B C 500 T)
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca13}
          alt="Disc/Plate Power RF-Capacitors CCG81"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor13;
