import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU998FIMG from "../../../assets/Ceramics(2.5-35KW)/FU-998F/FU-998F.jpg";
import DI from "../../../assets/Ceramics(2.5-35KW)/FU-998F/img1.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU-998F/img2.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU-998F/img3.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/FU-998F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU998F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU998FIMG} alt="" />
            <h2 className="text-xl">FU-998F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU998F is a direct thermal carbonization thorium-tungsten cathode
              type tube,the metal-ceramic triode,the anode cooling by forced
              air,the maximum anode dissipation power is 2KW,the maximum
              operating frequency is 50MHz,the output power of up to 4.0KW. the
              tube main used in industrial high frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU998F;
