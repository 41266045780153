import React from "react";
import Purchase from "../components/Purchase.jsx";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import SampleNavbar from "../components/SampleNavbar.jsx";

const ElectronValuePage = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="flex justify-center mb-10">
          <h1 className="text-3xl text-center">Electron Values</h1>
        </div>
        <div className="">
          <ul className="flex flex-col items-center">
            <li className="pb-3">
              <Link to="/GlassTriode-1-30KW">
                <button className=" border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Glass Triode(1-30KW)
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/GlassTriode-30-600KW">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Glass Triode(30-600KW)
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Ceramics-2.5-35KW">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900  hover:border-dashed w-60 rounded-xl lg:w-96">
                  Ceramics Triode(2.5-35KW)
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/CeramicsTriode-5-350KW">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Ceramics Triode(5-350KW)
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/AudioVacuumTube">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Audio vacuum tube
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Rectifier">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Rectifier
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Pentode">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Pentode
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Tetrode">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Tetrode
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Thyratron">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Thyratron
                </button>
              </Link>

              <hr />
            </li>
            <li className="pb-3">
              <Link to="/Magnetron">
                <button className="border-double border-4 border-blue-700 p-3 hover:text-blue-900 hover:border-dashed w-60 rounded-xl lg:w-96">
                  Magnetron
                </button>
              </Link>

              <hr />
            </li>
          </ul>
        </div>
      </div>

      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default ElectronValuePage;
