import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CT946FIMG from "../../../assets/Ceramics(2.5-35KW)/946F/946F.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/946F/img1.jpg";
import DI2 from "../../../assets/Ceramics(2.5-35KW)/946F/img2.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/946F/img3.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/946F/img4.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/946F/img5.jpg";
import Purchase from "../../../components/Purchase";

const CT946F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CT946FIMG} alt="" />
            <h2 className="text-xl">946F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              946F is a direct thermal carbonization thorium-tungsten
              cathode,matel-ceramic triode,the anode cooling method is forced
              air.the maximum anode dissipation power is 4.5KW,the maximum
              operating frequency is 110MHz,the output power is 9KW. the pipes
              are mainly used in industrial high frequency heating equipment.the
              tube can instead 7T69RB.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CT946F;
