import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU10SZIMG from "../../../assets/Glass Triode(1-30KW)/FU-10SZ/FU-10SZ.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/FU-10SZ/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/FU-10SZ/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/FU-10SZ/img3.jpg";
import TB3 from "../../../assets/Glass Triode(1-30KW)/572B/tb3.jpg";
import TB4 from "../../../assets/Glass Triode(1-30KW)/572B/tb4.jpg";
import TB5 from "../../../assets/Glass Triode(1-30KW)/572B/tb5.jpg";
import TB6 from "../../../assets/Glass Triode(1-30KW)/572B/tb6.jpg";
import Purchase from "../../../components/Purchase";

const GTFU10SZ = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU10SZIMG} alt="" />
            <h2 className="text-xl">FU-10S/Z Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-10S/Z is a direct thermal carbonization thorium-tungsten
              cathode,metallic glass triode anode cooling by water (S) and
              evaporative cooling (Z),the maximum anode dissipation power is
              10KW (S) and 15KW(Z),the maximum operating frequency is 26MHz,the
              output power is 15KW.the tubes are mainly used in industrial
              hihuipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU10SZ;
