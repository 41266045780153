import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU33IMG from "../../../assets/Glass Triode(1-30KW)/FU-33/FU-33.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/FU-33/IMG1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/FU-33/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/FU-33/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFU33 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU33IMG} alt="" />
            <h2 className="text-xl">FU-33 Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-33 is a direct thermal carbonization thorium-tungsten cathode
              glass triode anode cooling method is natural cooling,the maximum
              anode dissipation power is 30KW,the maximum operating frequency is
              30MHz,the output power is 1000W.the pipes are mainly used for
              amplification with the oscillation and radio equipment for
              industrial high frequency heating equipment.the tube can instead
              the U.S.833A/C.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU33;
