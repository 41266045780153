import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU3130FIMG from "../../../assets/Ceramics(2.5-35KW)/FU3130F/FU3130F.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/FU3130F/img1.jpg";
import DI2 from "../../../assets/Ceramics(2.5-35KW)/FU3130F/img2.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU3130F/img3.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU3130F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU3130F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU3130FIMG} alt="" />
            <h2 className="text-xl">FU3130F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU3130F is a direct thermal carbonization thorium-tungsten
              cathode,the metal-ceramic triode,the anode cooling by forced
              air,maximum anode power dissipation is 6KW,maximum operating
              frequency is 110MHz,the output power is 13KW,the tube is mainly
              used for industrial high frequency heating device.the tube can
              instead the E3130-tupe tubes.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU3130F;
