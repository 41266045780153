import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU3061FIMG from "../../../assets/Ceramics(2.5-35KW)/FU-3061F/FU-3061F.jpg";
import DI from "../../../assets/Ceramics(2.5-35KW)/FU-3061F/img1.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU-3061F/img2.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU-3061F/img3.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/FU-3061F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU3061F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU3061FIMG} alt="" />
            <h2 className="text-xl">FU-3061F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-3061F is a direct thermal carbonization thorium-tungsten
              cathode,metal-ceramic triode,the anode cooling method is forced
              air,the maximum anode dissipation power is 1.5KW,the maximum
              operating frequency is 50MHz,the output power is 2.5KW.the pipes
              are mainly used in industrial high frequency heating equipment.the
              tube can instead E3061C.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU3061F;
