import React from "react";
import "./necss.css";
// import "./nejs";
// import "./SampleNavbar.css";
import LG from "../assets/jtclogo.png";
import { Link } from "react-router-dom";

const SampleNavbar = () => {
  // function myFunction() {
  //   var x = document.getElementById("myTopnav");
  //   if (x.className === "topnav") {
  //     x.className += " responsive";
  //   } else {
  //     x.className = "topnav";
  //   }
  // }
  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  return (
    // <>
    //   <div className="navbar flex flex-row justify-between items-center content-center overflow-hidden">
    //     <Link className="logoLink" to="/Home">
    //       <img className="logo h-20 w-20" src={LG} alt="Logo" />
    //     </Link>
    //     <div className="flex flex-row justify-center items-center content-center mr-2 normalNavbar">
    //       <Link to="/Home">
    //         <button
    //           className="p-4 pb-5 text-gray-600 font-semibold hover:text-cyan-500"
    //           href="/home"
    //         >
    //           Home
    //         </button>
    //       </Link>
    //       <div className="dropdown">
    //         <button className="dropbtn p-3 text-gray-600 font-semibold">
    //           Products
    //         </button>
    //         <div className="dropdown-content">
    //           <Link to="/electronValue-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">
    //               Electron Value
    //             </a>
    //           </Link>
    //           <Link to="/capacitor-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">Capacitors</a>
    //           </Link>
    //           <Link to="/machinery-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">Machinery</a>
    //           </Link>
    //           <Link to="/printingStuffs-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">
    //               Printing Stuffs
    //             </a>
    //           </Link>
    //           <Link to="/mold-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">PVC Mold</a>
    //           </Link>
    //           <Link to="/battery-page">
    //             <a className="seeMore hover:text-cyan-500 p-2">Battery</a>
    //           </Link>
    //         </div>
    //       </div>
    //       <a
    //         className="m-3 text-gray-600 font-semibold hover:text-cyan-500"
    //         href="#contact"
    //       >
    //         Contact
    //       </a>
    //     </div>
    //   </div>
    // </>
    <>
      <div
        className="topnav flex item items-center justify-between"
        id="myTopnav"
      >
        <div>
          <Link to="/Home">
            <img className="logo h-20 w-20" src={LG} alt="Logo" />
          </Link>
        </div>
        <div className="threeBtn  mr-3">
          <div class="dropdown">
            <button class="dropbtn text-gray-600 font-semibold hover:text-cyan-500">
              Products
            </button>
            <div class="dropdown-content z-50">
              <Link to="/electronValue-page">
                <a className="seeMore hover:text-cyan-500 p-2">
                  Electron Value
                </a>
              </Link>
              <Link to="/capacitor-page">
                <a className="seeMore hover:text-cyan-500 p-2">Capacitors</a>
              </Link>
              <Link to="/machinery-page">
                <a className="seeMore hover:text-cyan-500 p-2">Machinery</a>
              </Link>
              <Link to="/printingStuffs-page">
                <a className="seeMore hover:text-cyan-500 p-2">
                  Printing Stuffs
                </a>
              </Link>
              <Link to="/mold-page">
                <a className="seeMore hover:text-cyan-500 p-2">PVC Mold</a>
              </Link>
              <Link to="/battery-page">
                <a className="seeMore hover:text-cyan-500 p-2">Battery</a>
              </Link>
            </div>
          </div>
          <Link to="/Home">
            <button
              className=" text-gray-600 font-semibold hover:text-cyan-500"
              href="/home"
            >
              Home
            </button>
          </Link>
          <a
            className="text-gray-600 font-semibold hover:text-cyan-500"
            href="#contact"
          >
            Contact
          </a>
          <a
            href="javascript:void(0);"
            style={({ fontSize: "30px" }, { margin: "20px" })}
            className="icon"
            onClick={myFunction}
          >
            <i class="fa-solid fa-bars"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default SampleNavbar;
