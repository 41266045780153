import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU824FFBSSBIMG from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/FU-824FFBSSB.jpg";
import DI1 from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/img1.jpg";
import DI2 from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/img2.jpg";
import DI3 from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/img3.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/img4.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU-824FFBSSB/img5.jpg";
import Purchase from "../../../components/Purchase";

const CTFU824FFBSSB = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU824FFBSSBIMG} alt="" />
            <h2 className="text-xl">FU-824F/FB/S/SB Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-824F/FB/S/SB type tube for direct thermal carbonization
              thorium-tungsten cathode metal ceramic triode,the anode cooling
              method for water-cooled (S/SB) and forced air (F/FB),the maximum
              anode dissipation power is 5KW,the maximum operating frequency is
              75MHz,the output power up to 10KW. the pipes are mainly used in
              industrial high frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
            <img className="w-96 h-96" src={DI3} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU824FFBSSB;
