import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase.jsx";
import Footer from "../../components/Footer.jsx";
import P300B from "../../assets/Pentode/300B.jpg";
import PKT88 from "../../assets/Pentode/KT88.jpg";
import PKT150 from "../../assets/Pentode/KT150.jpg";

const Pentode7 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Pentode</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/P300B-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={P300B} alt="300B" />
                <h1>300B</h1>
              </div>
            </button>
          </Link>
          <Link to="/PKT88-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={PKT88} alt="KT88" />
                <h1>KT88</h1>
              </div>
            </button>
          </Link>
          <Link to="/PKT150-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={PKT150} alt="KT150" />
                <h1>KT150</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Pentode7;
