import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import T4400CIMG from "../../../assets/Tetrode/Tetrode/4400C/4400C.jpg";
import DI from "../../../assets/Tetrode/Tetrode/4400C/img1.jpg";
import TB1 from "../../../assets/Tetrode/Tetrode/4400C/img3.jpg";
import TB2 from "../../../assets/Tetrode/Tetrode/4400C/img2.jpg";
import Purchase from "../../../components/Purchase";

const T4400C = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Tetrode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={T4400CIMG} alt="" />
            <h2 className="text-xl">4-400C Tetrode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              5867A is a direct thermal carbonization thorium-tungsten cathode
              glass triode anode cooling method is natural cooling,the maximum
              anode dissipation power is 400KW,the maximum operating frequency
              is 110MHz,the output power is 1.1KW.the tube in the RF power
              amplifier or modulator.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default T4400C;
