import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU433SIMG from "../../../assets/Glass Triode(30-600KW)/FU-433S/FU-433S.jpg";
import DI from "../../../assets/Glass Triode(30-600KW)/FU-433S/img1.jpg";
import TB1 from "../../../assets/Glass Triode(30-600KW)/FU-433S/img2.jpg";
import TB2 from "../../../assets/Glass Triode(30-600KW)/FU-433S/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFU433S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(30-600KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU433SIMG} alt="" />
            <h2 className="text-xl">FU-433S Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-433S is a directly heated pure tungsten cathode,metallic glass
              transistor,water-cooled anode,the maximum anode dissipation power
              is 60KW,the maximum operating frequency is 6MHz,the output power
              is 100KW.The tubes are mainly used in industrial high frequency
              heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU433S;
