import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU954SIMG from "../../../assets/Ceramics Triode(5-350KW)/FU954S/FU954S.jpg";
import DI1 from "../../../assets/Ceramics Triode(5-350KW)/FU954S/img1.jpg";
import DI2 from "../../../assets/Ceramics Triode(5-350KW)/FU954S/img2.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU954S/img3.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU954S/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU954S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU954SIMG} alt="" />
            <h2 className="text-xl">FU954S Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU954S is a thorium-tungeten cathode of the direct thermal
              carbonization,metal-ceramic triode,the anode cooling method is
              water-cooled,the maximum anode dissipation power is 120KW output
              power is 200KW,maximum operating frequency is 6MHZ,the tube is
              mainly used for industrial high frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU954S;
