import React from "react";
import SampleNavbar from "../components/SampleNavbar.jsx";
import Footer from "../components/Footer.jsx";
import Purchase from "../components/Purchase.jsx";
import PC1 from "../assets/Printing Stuffs/PC1.jpg";
import PC2 from "../assets/Printing Stuffs/PC2.jpg";
import PC3 from "../assets/Printing Stuffs/PC3.jpg";
import PC4 from "../assets/Printing Stuffs/PC4.jpg";
import PC5 from "../assets/Printing Stuffs/PC5.jpg";
import PC6 from "../assets/Printing Stuffs/PC6.jpg";
import PC7 from "../assets/Printing Stuffs/PC7.jpg";
import PC8 from "../assets/Printing Stuffs/PC8.jpg";
import PC9 from "../assets/Printing Stuffs/PC9.jpg";
import PC10 from "../assets/Printing Stuffs/PC10.jpg";
import PC11 from "../assets/Printing Stuffs/PC11.jpg";
import PC12 from "../assets/Printing Stuffs/PC12.jpg";
import PC13 from "../assets/Printing Stuffs/PC13.jpg";
import PC14 from "../assets/Printing Stuffs/PC14.jpg";
import PC15 from "../assets/Printing Stuffs/PC15.jpg";
import PC16 from "../assets/Printing Stuffs/PC16.jpg";
import PC17 from "../assets/Printing Stuffs/PC17.jpg";

const PrintingStuffsPage = () => {
  return (
    //     <>
    //       <div>
    //         <SampleNavbar />
    //       </div>
    //       <div>
    //         <div className="flex items-center justify-center mt-5">
    //           <h1 className="text-3xl text-black">PRINTING STUFF</h1>
    //         </div>
    //         <div>
    //           <div className="flex justify-evenly m-10">
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC1}
    //                   alt="Mini Heat Press Machine"
    //                 />
    //                 <h1>Mini Heat Press Machine</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC2}
    //                   alt="Water Based YT 1080 Photo Emulsion & Gold Color"
    //                 />
    //                 <h1>Water Based YT 1080 Photo Emulsion & Gold Color</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC3} alt="Aluminum Handle" />
    //                 <h1>Aluminum Handle</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC4} alt="Hinge Clamp" />
    //                 <h1>Hinge Clamp</h1>
    //               </div>
    //             </button>
    //           </div>
    //           <div className="flex justify-evenly m-10">
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC5}
    //                   alt="80x100CM Pneumatic Heat Press Machine"
    //                 />
    //                 <h1>80x100CM Pneumatic Heat Press Machine</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC6} alt="Air Compressor" />
    //                 <h1>Air Compressor</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC7} alt="3D-Printer" />
    //                 <h1>3D-Printer</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC8}
    //                   alt="Pneumatic CAP Heat Press Machine"
    //                 />
    //                 <h1>Pneumatic CAP Heat Press Machine</h1>
    //               </div>
    //             </button>
    //           </div>
    //           <div className="flex justify-evenly m-10">
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC9} alt="Aluminum Frame" />
    //                 <h1>Aluminum Frame</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC10}
    //                   alt="Screen Printing 4 Color Machine"
    //                 />
    //                 <h1>Screen Printing 4 Color Machine</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC11}
    //                   alt="Screen Printing Emulsion Remover"
    //                 />
    //                 <h1>Screen Printing Emulsion Remover</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC12}
    //                   alt="High Quality Squeegee Rubber Cutter"
    //                 />
    //                 <h1>High Quality Squeegee Rubber Cutter</h1>
    //               </div>
    //             </button>
    //           </div>
    //           <div className="flex justify-evenly m-10">
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80"
    //                   src={PC13}
    //                   alt="Screen Printing Frame Rack
    // "
    //                 />
    //                 <h1>Screen Printing Frame Rack</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img
    //                   className="w-80 h-80 relative"
    //                   src={PC14}
    //                   alt="Stainless Steel Spatula"
    //                 />
    //                 <h1>Stainless Steel Spatula</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC15} alt="PVC Squeegee" />
    //                 <h1>PVC Squeegee</h1>
    //               </div>
    //             </button>
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC16} alt="Exposure Machine" />
    //                 <h1>Exposure Machine</h1>
    //               </div>
    //             </button>
    //           </div>
    //           <div className="flex justify-evenly m-10">
    //             <button class="shadow-lg5shadow-gray-300 hover:shadow-blue-900 w-80 h-96 cursor-default p-5">
    //               <div className="flex flex-col items-center font-bold">
    //                 <img className="w-80 h-80" src={PC17} alt="Water Based Ink" />
    //                 <h1>Water Based Ink</h1>
    //               </div>
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //       <div>
    //         <Purchase />
    //         <Footer />
    //       </div>
    //     </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-black text-center">PRINTING STUFF</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC1}
              alt="Mini Heat Press Machine"
            />
            <h1>Mini Heat Press Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC2}
              alt="Water Based YT 1080 Photo Emulsion & Gold Color"
            />
            <h1>Water Based YT 1080 Photo Emulsion & Gold Color</h1>
          </div>

          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC3} alt="Aluminum Handle" />
            <h1>Aluminum Handle</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC4} alt="Hinge Clamp" />
            <h1>Hinge Clamp</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC5}
              alt="80x100CM Pneumatic Heat Press Machine"
            />
            <h1>80x100CM Pneumatic Heat Press Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC6} alt="Air Compressor" />
            <h1>Air Compressor</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC7} alt="3D-Printer" />
            <h1>3D-Printer</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC8}
              alt="Pneumatic CAP Heat Press Machine"
            />
            <h1>Pneumatic CAP Heat Press Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC9} alt="Aluminum Frame" />
            <h1>Aluminum Frame</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC10}
              alt="Screen Printing 4 Color Machine"
            />
            <h1>Screen Printing 4 Color Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC11}
              alt="Screen Printing Emulsion Remover"
            />
            <h1>Screen Printing Emulsion Remover</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC12}
              alt="High Quality Squeegee Rubber Cutter"
            />
            <h1>High Quality Squeegee Rubber Cutter</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PC13}
              alt="Screen Printing Frame Rack"
            />
            <h1>Screen Printing Frame Rack</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80 relative"
              src={PC14}
              alt="Stainless Steel Spatula"
            />
            <h1>Stainless Steel Spatula</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC15} alt="PVC Squeegee" />
            <h1>PVC Squeegee</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC16} alt="Exposure Machine" />
            <h1>Exposure Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-5 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={PC17} alt="Water Based Ink" />
            <h1>Water Based Ink</h1>
          </div>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default PrintingStuffsPage;
