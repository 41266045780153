import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import CT946F from "../../assets/Ceramics(2.5-35KW)/946F.jpg";
import CT947F from "../../assets/Ceramics(2.5-35KW)/947F.jpg";
import CTFU998F from "../../assets/Ceramics(2.5-35KW)/FU.998F.jpg";
import CTFU3061F from "../../assets/Ceramics(2.5-35KW)/FU.3061F.jpg";
import CTFU3062F from "../../assets/Ceramics(2.5-35KW)/FU.3062F.jpg";
import CTFU8725F from "../../assets/Ceramics(2.5-35KW)/FU.8725F.jpg";
import CTFU957F from "../../assets/Ceramics(2.5-35KW)/FU957F.jpg";
import CTFU959F from "../../assets/Ceramics(2.5-35KW)/FU959F.jpg";
import CTFU3069FFA from "../../assets/Ceramics(2.5-35KW)/FU3069F.FA.jpg";
import CTFU3130F from "../../assets/Ceramics(2.5-35KW)/FU3130F.jpg";
import CTFU8785F from "../../assets/Ceramics(2.5-35KW)/FU8785F.jpg";
import CTFU8787F from "../../assets/Ceramics(2.5-35KW)/FU8787F.jpg";

const CeramicsTriode3 = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div>
    //     <div className="flex items-center justify-center mt-5">
    //       <h1 className="text-3xl">Ceramics Triode (2.5-35KW)</h1>
    //     </div>
    //     <div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/946F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CT946F} alt="946F" />
    //               <h1>946F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/947F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CT947F} alt="947F" />
    //               <h1>947F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU998F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU998F} alt="FU-998F" />
    //               <h1>FU-998F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU3061F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU3061F} alt="FU-3061F" />
    //               <h1>FU-3061F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU3062F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU3062F} alt="FU-3062F" />
    //               <h1>FU-3062F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU8725F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU8725F} alt="FU-8725F" />
    //               <h1>FU-8725F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU957F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU957F} alt="FU957F" />
    //               <h1>FU957F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU959F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU959F} alt="FU959F" />
    //               <h1>FU959F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU3069FFA-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img
    //                 className="w-60 h-60"
    //                 src={CTFU3069FFA}
    //                 alt="FU3069F/FA"
    //               />
    //               <h1>FU3069F/FA</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU3130F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU3130F} alt="FU3130F" />
    //               <h1>FU3130F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU8785F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU8785F} alt="FU8785F" />
    //               <h1>FU8785F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU8787F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU8787F} alt="FU8787F" />
    //               <h1>FU8787F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Ceramics Triode (2.5-35KW)</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/946F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CT946F} alt="946F" />
                <h1>946F</h1>
              </div>
            </button>
          </Link>
          <Link to="/947F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CT947F} alt="947F" />
                <h1>947F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU998F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU998F} alt="FU-998F" />
                <h1>FU-998F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU3061F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU3061F} alt="FU-3061F" />
                <h1>FU-3061F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU3062F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU3062F} alt="FU-3062F" />
                <h1>FU-3062F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU8725F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU8725F} alt="FU-8725F" />
                <h1>FU-8725F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU957F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU957F} alt="FU957F" />
                <h1>FU957F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU959F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU959F} alt="FU959F" />
                <h1>FU959F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU3069FFA-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU3069FFA} alt="FU3069F/FA" />
                <h1>FU3069F/FA</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU3130F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU3130F} alt="FU3130F" />
                <h1>FU3130F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU8785F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU8785F} alt="FU8785F" />
                <h1>FU8785F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU8787F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU8787F} alt="FU8787F" />
                <h1>FU8787F</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CeramicsTriode3;
