import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase.jsx";
import Footer from "../../components/Footer.jsx";
import TNL5557 from "../../assets/Thyratron/NL.5557.jpg";

const Thyratron9 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Thyratron</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/TNL5557-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-60 h-60"
                  src={TNL5557}
                  alt="NL-5557 Type Thyratron"
                />
                <h1>NL-5557 Type Thyratron</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Thyratron9;
