import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFD911SCIMG from "../../../assets/Ceramics Triode(5-350KW)/FD-911SC/FD-911SC.jpg";
import DI1 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SC/img1.jpg";
import DI2 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SC/img2.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SC/img3.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SC/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFD911SC = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFD911SCIMG} alt="" />
            <h2 className="text-xl">FD-911SC Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FD-911SC a direct thermal carbonization thorium-tungsten
              cathode,the matel-ceramic triode,anode cooling is by
              water-cooled,the maximum anode dissipation power is 80KW,the
              maximum operating frequency is 26MHz,the output power is 100KW.the
              tube is mainly used for industrial high frequency heating
              equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFD911SC;
