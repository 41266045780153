import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU308SIMG from "../../../assets/Glass Triode(30-600KW)/FU-308S/FU-308S.jpg";
import DI1 from "../../../assets/Glass Triode(30-600KW)/FU-308S/img1.jpg";
import DI2 from "../../../assets/Glass Triode(30-600KW)/FU-308S/img2.jpg";
import TB1 from "../../../assets/Glass Triode(30-600KW)/FU-308S/img3.jpg";
import TB2 from "../../../assets/Glass Triode(30-600KW)/FU-308S/img4.jpg";
import Purchase from "../../../components/Purchase";

const GTFU308S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(30-600KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU308SIMG} alt="" />
            <h2 className="text-xl">FU-308S Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-308S is a direct thermal carbonization thorium-tungsten
              cathode,metal glass triode.Anode cooled by water,the maximum anode
              dissipation power is 60KW,the maximum operating frequency is
              26MHZ,the output power up to 60KW,the tube is mainly used for
              industrial high frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU308S;
