import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import R577IMG from "../../../assets/Rectifier/577.jpg";
import DI1 from "../../../assets/Rectifier/img1.jpg";
import DI2 from "../../../assets/Rectifier/img2.jpg";
import TB1 from "../../../assets/Rectifier/img3.jpg";
import TB2 from "../../../assets/Rectifier/img4.jpg";
import Purchase from "../../../components/Purchase";
const R577 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Rectifier</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={R577IMG} alt="" />
            <h2 className="text-xl">577 Rectifier</h2>
            <br />
            <p className="w-full lg:w-3/5">
              577 is a spiral direct thermal carbonization thorium-tungstrn
              cathode glass diode cooling by forced air anode temperature does
              not exceed 800℃ ,the yube is mainly used for radio transmission
              equipment for the rectifier.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default R577;
