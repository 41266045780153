import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU959FIMG from "../../../assets/Ceramics(2.5-35KW)/FU959F/FU959F.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/FU959F/img1.jpg";
import DI2 from "../../../assets/Ceramics(2.5-35KW)/FU959F/img2.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU959F/img3.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU959F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU959F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU959FIMG} alt="" />
            <h2 className="text-xl">FU959F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU959F is a metal ceramic structure of the oxide cathode,forced
              air-cooled,high amplification factor of transistor.rated anode
              dissipation power is 1500W,the GRID power dissipation rating is
              25W,the maximum operating frequency is 250MHz,mainly used in the
              cathode incentive AB2 and Class B amplifier, and cathode
              excitation anode modulated class C amplifier,with the U.S.Eimac
              production of the 3CX1500A7 type tube used interchangeably.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU959F;
