import React from "react";
import Footer from "../../components/Footer";
import Ca4 from "../../assets/capacitor/ca_watermark (1)_page-0004.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";

const Capacitor4 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Watercooled Power RF-Capacitors(Internal Cooling) Contoured rim
          protected with silicone rubber
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca4}
          alt="Watercooled Power RF-Capacitors(Internal Cooling) Contoured
                  rim protected with silicone rubber"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor4;
