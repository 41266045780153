import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU957FIMG from "../../../assets/Ceramics(2.5-35KW)/FU957F/FU957F.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/FU957F/img1.jpg";
import DI2 from "../../../assets/Ceramics(2.5-35KW)/FU957F/img2.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU957F/img3.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU957F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU957F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU957FIMG} alt="" />
            <h2 className="text-xl">FU957F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU957F is a thorium-tungsten cathode of the direct thermal
              carbonization, the metal-ceramic triode,the anode cooling is
              forced air,the maximum anode dissipation power is 1500W,the
              maximum operating frequency is 110MHz,the output power of up to
              2.8KW,the pipe used mainly for industrial frequency heating
              equipment for oscillation and radio equipment for
              amplification.the tube can insteadwith the United States 3CX1500D3
              tube.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU957F;
