import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import AVT212IMG from "../../../assets/Audio vacuum tube/212/212.jpg";
import DI from "../../../assets/Audio vacuum tube/212/img1.jpg";
import TB1 from "../../../assets/Audio vacuum tube/212/img2.jpg";
import TB2 from "../../../assets/Audio vacuum tube/212/img3.jpg";
import TB3 from "../../../assets/Audio vacuum tube/212/img4.jpg";
import Purchase from "../../../components/Purchase";

const AVT212 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Audio Vacuum Tube</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={AVT212IMG} alt="" />
            <h2 className="text-xl">212 Audio Vacuum Tube</h2>
            <br />
            <p className="w-full lg:w-3/5">
              212 type vavle is directly heated glass shell structure type
              thorium tungsten carbide natural cooling cathode triode.the anode
              maximum disspation power is 400W.the working frequency is
              4.5MHz,when the frequency is not higher than 1.5MHz,c class cable
              state output power is 400W. the vavle is suitable for audio
              amplifier for low frequency power amplifier,oscillation and also
              can be being done in the rf oscillator amplifier.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default AVT212;
