import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU912ZIMG from "../../../assets/Ceramics Triode(5-350KW)/FU912Z/FU912Z.jpg";
import DI from "../../../assets/Ceramics Triode(5-350KW)/FU912Z/img1.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU912Z/img2.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU912Z/img3.jpg";
import Purchase from "../../../components/Purchase";

const CTFU912Z = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU912ZIMG} alt="" />
            <h2 className="text-xl">FU-912Z Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-912Z is a direct thermal carbonization thorium-tungsten
              cathode,the metal-ceramic triode.anode cooling is evaporative
              cooking,the maximum anode dissipation power is 150KW,maximum
              operating frequency is 30MHz,the pipes are mainly used for
              oscillation in radio equipment,high frequency power
              amplifier,audio amplifier with.The tube can instead the French
              TH540C.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU912Z;
