import React from "react";
import Footer from "../../components/Footer";
import Ca8 from "../../assets/capacitor/ca_watermark (1)_page-0008.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";

const Capacitor8 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Tube-Style Power RF-Capacitors CCG20
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca8}
          alt="Tube-Style Power RF-Capacitors CCG20"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor8;
