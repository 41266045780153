import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GT572BIMG from "../../../assets/Glass Triode(1-30KW)/572B/img1.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/572B/img2.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/572B/tb1.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/572B/tb2.jpg";
import TB3 from "../../../assets/Glass Triode(1-30KW)/572B/tb3.jpg";
import TB4 from "../../../assets/Glass Triode(1-30KW)/572B/tb4.jpg";
import TB5 from "../../../assets/Glass Triode(1-30KW)/572B/tb5.jpg";
import TB6 from "../../../assets/Glass Triode(1-30KW)/572B/tb6.jpg";
import Purchase from "../../../components/Purchase";

const GT572B = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GT572BIMG} alt="" />
            <h2 className="text-xl">572B Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              572B electron tube is a broken-line, direct-heating thorium-coated
              tungsten cathode, a graphite grid, a natural cooling or forced
              air-cooling glass-shell structure triode, the maximum anode
              dissipation power is 125W, the output power can be up to 300W if
              the frequence is not more than 30MHz. This electron tube is
              suitable for power amplification or oscillation in the industrial
              heating field, and the quality is reliable.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <p>The main parameters 1：</p>
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <p>The main parameters2：</p>
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <p>The main parameters3：</p>
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
            <br />
            <p>
              The main parameters4：Typical working state-single tube class A
              amplification
            </p>
            <img className="w-full lg:w-2/5" src={TB4} alt="" />
            <br />
            <p>
              The main parameters5：Typical working state-double-tube class AB
              amplification
            </p>
            <img className="w-full lg:w-2/5" src={TB5} alt="" />
            <br />
            <p>
              The main parameters6： Typical working status-RF power amplifier
              and oscillator Class C telegraph status
            </p>
            <img className="w-full lg:w-2/5" src={TB6} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GT572B;
