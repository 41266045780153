import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import TTM927FIMG from "../../../assets/Tetrode/Tetrode/TM927F/TM927F.jpg";
import DI from "../../../assets/Tetrode/Tetrode/TM927F/img1.jpg";
import TB1 from "../../../assets/Tetrode/Tetrode/TM927F/img2.jpg";
import TB2 from "../../../assets/Tetrode/Tetrode/TM927F/img3.jpg";
import Purchase from "../../../components/Purchase";

const TTM927F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Tetrode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={TTM927FIMG} alt="" />
            <h2 className="text-xl">TM927F Tetrode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              TM927F is a direct thermal carbonization thorium-tungsten cathode
              for TM927F,the metallic glassstructure,high-voltage high-power
              pulse modulation diode anode cooling by forced air,the
              maximumanode voltage is 45KV,the maximum anode pulse current is
              12A,the tude is mainly used for radarmodulator for rigid
              modulation pipe.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default TTM927F;
