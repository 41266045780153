import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import CTFD911SA from "../../assets/Ceramics Triode(5-350KW)/FD.911SA.jpg";
import CTFD911SC from "../../assets/Ceramics Triode(5-350KW)/FD.911SC.jpg";
import CTFU824FFBSSB from "../../assets/Ceramics Triode(5-350KW)/FU.824F.FB.S.SB.jpg";
import CTFU834FASA from "../../assets/Ceramics Triode(5-350KW)/FU.834FA.SA.jpg";
import CTFU836F from "../../assets/Ceramics Triode(5-350KW)/FU.836F.jpg";
import CT912Z from "../../assets/Ceramics Triode(5-350KW)/FU.912Z.jpg";
import CTFU954S from "../../assets/Ceramics Triode(5-350KW)/FU954S.jpg";
import CTFU955S from "../../assets/Ceramics Triode(5-350KW)/FU955S.jpg";
import CTFU956S from "../../assets/Ceramics Triode(5-350KW)/FU956S.jpg";
import CTFU958S from "../../assets/Ceramics Triode(5-350KW)/FU958S.jpg";
import CTFU3092CA from "../../assets/Ceramics Triode(5-350KW)/FU3092CA.jpg";
import CTFU9794S from "../../assets/Ceramics Triode(5-350KW)/FU9794S.jpg";

const CeramicsTriode4 = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div>
    //     <div className="flex items-center justify-center mt-5">
    //       <h1 className="text-3xl">Ceramics Triode (5-350KW)</h1>
    //     </div>
    //     <div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FD911SA-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFD911SA} alt="FD-911SA" />
    //               <h1>FD-911SA</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FD911SC-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFD911SC} alt="FD-911SC" />
    //               <h1>FD-911SC</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU824FFBSSB-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img
    //                 className="w-60 h-60"
    //                 src={CTFU824FFBSSB}
    //                 alt="FU-824F/FB/S/SB"
    //               />
    //               <h1>FU-824F/FB/S/SB</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU834FASA-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img
    //                 className="w-60 h-60"
    //                 src={CTFU834FASA}
    //                 alt="FU-834FA/SA"
    //               />
    //               <h1>FU-834FA/SA</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU836F-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU836F} alt="FU-836F" />
    //               <h1>FU-836F</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU912Z-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CT912Z} alt="FU-912Z" />
    //               <h1>FU-912Z</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU954S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU954S} alt="FU954S" />
    //               <h1>FU954S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU955S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU955S} alt="FU955S" />
    //               <h1>FU955S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU956S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU956S} alt="FU956S" />
    //               <h1>FU956S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU958S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU958S} alt="FU958S" />
    //               <h1>FU958S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU3092CA-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU3092CA} alt="FU3092CA" />
    //               <h1>FU3092CA</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU9794S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={CTFU9794S} alt="FU9794S" />
    //               <h1>FU9794S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Ceramics Triode (5-350KW)</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/FD911SA-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFD911SA} alt="FD-911SA" />
                <h1>FD-911SA</h1>
              </div>
            </button>
          </Link>
          <Link to="/FD911SC-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFD911SC} alt="FD-911SC" />
                <h1>FD-911SC</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU824FFBSSB-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-60 h-60"
                  src={CTFU824FFBSSB}
                  alt="FU-824F/FB/S/SB"
                />
                <h1>FU-824F/FB/S/SB</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU834FASA-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img
                  className="w-60 h-60"
                  src={CTFU834FASA}
                  alt="FU-834FA/SA"
                />
                <h1>FU-834FA/SA</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU836F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU836F} alt="FU-836F" />
                <h1>FU-836F</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU912Z-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CT912Z} alt="FU-912Z" />
                <h1>FU-912Z</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU954S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU954S} alt="FU954S" />
                <h1>FU954S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU955S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU955S} alt="FU955S" />
                <h1>FU955S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU956S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU956S} alt="FU956S" />
                <h1>FU956S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU958S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU958S} alt="FU958S" />
                <h1>FU958S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU3092CA-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU3092CA} alt="FU3092CA" />
                <h1>FU3092CA</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU9794S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={CTFU9794S} alt="FU9794S" />
                <h1>FU9794S</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CeramicsTriode4;
