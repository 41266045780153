import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase.jsx";
import Footer from "../../components/Footer.jsx";
import T4400C from "../../assets/Tetrode/4.400C.jpg";
import TTM5187F from "../../assets/Tetrode/TM.5187F.jpg";
import TM927F from "../../assets/Tetrode/TM927F.jpg";

const Tetrode8 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Tetrode</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/T4400C-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={T4400C} alt="4-400C" />
                <h1>4-400C</h1>
              </div>
            </button>
          </Link>
          <Link to="/TTM5187F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={TTM5187F} alt="TM-5187F" />
                <h1>TM-5187F</h1>
              </div>
            </button>
          </Link>
          <Link to="/TTM927F-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={TM927F} alt="TM927F" />
                <h1>TM927F</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Tetrode8;
