import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU8787FIMG from "../../../assets/Ceramics(2.5-35KW)/FU8787F/FU8787F.jpg";
import DI from "../../../assets/Ceramics(2.5-35KW)/FU8787F/img1.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU8787F/img2.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU8787F/img3.jpg";
import TB3 from "../../../assets/Ceramics(2.5-35KW)/FU8787F/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU8787F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU8787FIMG} alt="" />
            <h2 className="text-xl">FU8787F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU8787F is a direct thermal carbonization thorium-tungsten
              cathode,the anode cooling by forced air,the maximum anode
              dissipation power is 20KW,the maximum operating frequency is
              40MHz,the output power is 35KW.the tube used mainly for industrial
              in the frequency heating equipment.the tube can be produced in
              Japan 8T87RB type tubes are used interchangeably.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU8787F;
