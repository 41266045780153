import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import GT3500Z from "../../assets/Glass Triode(1-30KW)/3 500Z.jpg";
import GT6T40 from "../../assets/Glass Triode(1-30KW)/6T40.jpg";
import GT572B from "../../assets/Glass Triode(1-30KW)/572B.jpg";
import GT5867A from "../../assets/Glass Triode(1-30KW)/5867A.jpg";
import GT5868 from "../../assets/Glass Triode(1-30KW)/5868.jpg";
import GT7092 from "../../assets/Glass Triode(1-30KW)/7092.jpg";
import GTFU10SZ from "../../assets/Glass Triode(1-30KW)/FU 10S Z.jpg";
import GTFU33 from "../../assets/Glass Triode(1-30KW)/FU 33.jpg";
import GTFU200 from "../../assets/Glass Triode(1-30KW)/FU 200.jpg";
import GTFU307S from "../../assets/Glass Triode(1-30KW)/FU 307S.jpg";
import GTFU501 from "../../assets/Glass Triode(1-30KW)/FU 501.jpg";
import GTFU606 from "../../assets/Glass Triode(1-30KW)/FU 606.jpg";
import GTFU605 from "../../assets/Glass Triode(1-30KW)/FU 605.jpg";

const GlassTriode1 = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div>
    //     <div className="flex items-center justify-center mt-5">
    //       <h1 className="text-3xl">Glass Triode (1-30KW)</h1>
    //     </div>
    //     <div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/3500Z-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT3500Z} alt="3-500Z" />
    //               <h1>3-500Z</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/6T40-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT6T40} alt="6T40" />
    //               <h1>6T40</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/572B-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT572B} alt="572B" />
    //               <h1>572B</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/5867A-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT5867A} alt="5867A" />
    //               <h1>5867A</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/5868-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT5868} alt="5868" />
    //               <h1>5868</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/7092-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GT7092} alt="7092" />
    //               <h1>7092</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU10SZ-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU10SZ} alt="FU-10S/Z" />
    //               <h1>FU-10S/Z</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU33-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU33} alt="FU-33" />
    //               <h1>FU-33</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU200-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU200} alt="FU-200" />
    //               <h1>FU-200</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU307S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU307S} alt="FU-307S" />
    //               <h1>FU-307S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU501-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU501} alt="FU-501" />
    //               <h1>FU-501</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU606-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU606} alt="FU-606" />
    //               <h1>FU-606</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FU605-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU605} alt="FU-605" />
    //               <h1>FU-605</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Glass Triode (1-30KW)</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/3500Z-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT3500Z} alt="3-500Z" />
                <h1>3-500Z</h1>
              </div>
            </button>
          </Link>
          <Link to="/6T40-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT6T40} alt="6T40" />
                <h1>6T40</h1>
              </div>
            </button>
          </Link>
          <Link to="/572B-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT572B} alt="572B" />
                <h1>572B</h1>
              </div>
            </button>
          </Link>
          <Link to="/5867A-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT5867A} alt="5867A" />
                <h1>5867A</h1>
              </div>
            </button>
          </Link>
          <Link to="/5868-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT5868} alt="5868" />
                <h1>5868</h1>
              </div>
            </button>
          </Link>
          <Link to="/7092-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GT7092} alt="7092" />
                <h1>7092</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU10SZ-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU10SZ} alt="FU-10S/Z" />
                <h1>FU-10S/Z</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU33-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU33} alt="FU-33" />
                <h1>FU-33</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU200-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU200} alt="FU-200" />
                <h1>FU-200</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU307S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU307S} alt="FU-307S" />
                <h1>FU-307S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU501-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU501} alt="FU-501" />
                <h1>FU-501</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU606-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU606} alt="FU-606" />
                <h1>FU-606</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU605-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU605} alt="FU-605" />
                <h1>FU-605</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GlassTriode1;
