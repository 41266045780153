import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GT3500ZIMG from "../../../assets/Glass Triode(1-30KW)/3500Z/3500Z.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/3500Z/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/3500Z/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/3500Z/img3.jpg";
import Purchase from "../../../components/Purchase";
const GT3500Z = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GT3500ZIMG} alt="" />
            <h2 className="text-xl">3-500Z Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              3-500Z is a direct thermal carbonization thorium-tungsten cathode,
              glass triode,the anode cooling method is natural cooling,the
              maximum anode dissipation power is 500W,the maximum operating
              frequency is 110MHz,the output power is 850W.the tube for
              industrial heating equipment for power amplification or
              oscillation.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GT3500Z;
