import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import GTFD911S from "../../assets/Glass Triode(30-600KW)/FD.911S.jpg";
import GTFD912S from "../../assets/Glass Triode(30-600KW)/FD.912S.jpg";
import GTFD950S from "../../assets/Glass Triode(30-600KW)/FD.950S.jpg";
import GTFD934S from "../../assets/Glass Triode(30-600KW)/FD934S.jpg";
import GTFD935S from "../../assets/Glass Triode(30-600KW)/FD935S.jpg";
import GTFU308S from "../../assets/Glass Triode(30-600KW)/FU.308S.jpg";
import GTFU431S from "../../assets/Glass Triode(30-600KW)/FU.431S.jpg";
import GTFU433S from "../../assets/Glass Triode(30-600KW)/FU.433S.jpg";

const GlassTriode2 = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div>
    //     <div className="flex items-center justify-center mt-5">
    //       <h1 className="text-3xl">Glass Triode (30-600KW)</h1>
    //     </div>
    //     <div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FD911S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFD911S} alt="FD-911S" />
    //               <h1>FD-911S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FD912S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFD912S} alt="FD-912S" />
    //               <h1>FD-912S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FD934S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFD934S} alt="FD934S" />
    //               <h1>FD934S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FD935S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFD935S} alt="FD935S" />
    //               <h1>FD935S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <Link to="/FD950S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFD950S} alt="FD-950S" />
    //               <h1>FD-950S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU308S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU308S} alt="FU-308S" />
    //               <h1>FU-308S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU431S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU431S} alt="FU-431S" />
    //               <h1>FU-431S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //         <Link to="/FU433S-Page">
    //           <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125">
    //             <div className="flex flex-col items-center font-bold">
    //               <img className="w-60 h-60" src={GTFU433S} alt="FU-433S" />
    //               <h1>FU-433S</h1>
    //             </div>
    //           </button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Glass Triode (30-600KW)</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/FD911S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFD911S} alt="FD-911S" />
                <h1>FD-911S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FD912S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFD912S} alt="FD-912S" />
                <h1>FD-912S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FD934S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFD934S} alt="FD934S" />
                <h1>FD934S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FD935S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFD935S} alt="FD935S" />
                <h1>FD935S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FD950S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFD950S} alt="FD-950S" />
                <h1>FD-950S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU308S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU308S} alt="FU-308S" />
                <h1>FU-308S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU431S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU431S} alt="FU-431S" />
                <h1>FU-431S</h1>
              </div>
            </button>
          </Link>
          <Link to="/FU433S-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={GTFU433S} alt="FU-433S" />
                <h1>FU-433S</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GlassTriode2;
