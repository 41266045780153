import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import TNL5557IMG from "../../../assets/Thyratron/NL5557.jpg";
import DI from "../../../assets/Thyratron/img1.jpg";
import TB1 from "../../../assets/Thyratron/img2.jpg";
import TB2 from "../../../assets/Thyratron/img3.jpg";
import TB3 from "../../../assets/Thyratron/img4.jpg";
import TB4 from "../../../assets/Thyratron/img5.jpg";
import Purchase from "../../../components/Purchase";

const TNL5557 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Thyratron</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={TNL5557IMG} alt="" />
            <h2 className="text-xl">NL-5557 Thyratron</h2>
            <br />
            <p className="w-full lg:w-3/5">
              NL-5557 type thyratron is a glass-shell structure, direct-heating
              oxide cathode, natural cooling mercury-filled thyratron, mainly
              used for the protection of small and medium-sized power
              high-frequency equipment, spark-proof circuits, stable quality and
              high reliability .
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB4} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default TNL5557;
