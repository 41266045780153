import React from "react";
import Footer from "../components/Footer.jsx";
import Purchase from "../components/Purchase.jsx";
import HPVCWM8KW from "../assets/Machine/8KW Hydraulic PVC welding machine.jpg";
import PPVCWM8KW from "../assets/Machine/8KW Penumatic PVC welding machine.jpg";
import LMM20W from "../assets/Machine/20W Laser marking Machine.jpg";
import CNCEM from "../assets/Machine/CNC Engraving Machine.jpg";
import CNCR2_5WK from "../assets/Machine/CNC Router 5.2KW.jpg";
import LEM30W from "../assets/Machine/Laser Engraving Machine 30W.jpg";
import OEM from "../assets/Machine/Oil Extraction Machine.jpg";
import EDMDM from "../assets/Machine/EDM Drilling Machine.jpg";
import FLCM from "../assets/Machine/Fiber laser cutting machine .jpg";
import SampleNavbar from "../components/SampleNavbar.jsx";

const MachineryPage = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div className="flex items-center justify-center mt-5">
    //     <h1 className="text-3xl">Machines</h1>
    //   </div>
    //   <div>
    //     <div className="flex justify-evenly m-10">
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold ">
    //           <img
    //             className="w-80 h-80"
    //             src={HPVCWM8KW}
    //             alt="Hydraulic PVC welding machine 8KW"
    //           />
    //           <h1>Hydraulic PVC welding machine 8KW</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={PPVCWM8KW}
    //             alt="Penumatic PVC welding machine 8KW"
    //           />
    //           <h1>Penumatic PVC welding machine 8KW</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={LMM20W}
    //             alt="Laser marking Machine 20W"
    //           />
    //           <h1>Laser marking Machine 20W</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={CNCEM}
    //             alt="CNC Engraving Machine"
    //           />
    //           <h1>CNC Engraving Machine</h1>
    //         </div>
    //       </button>
    //     </div>
    //     <div className="flex justify-evenly m-10">
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={OEM}
    //             alt="Oil Extraction Machine"
    //           />
    //           <h1>Oil Extraction Machine</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={LEM30W}
    //             alt="Laser Engraving Machine 30W"
    //           />
    //           <h1>Laser Engraving Machine 30W</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={CNCR2_5WK}
    //             alt="CNC Router 5.2KW"
    //           />
    //           <h1>CNC Router 5.2KW</h1>
    //         </div>
    //       </button>
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={EDMDM}
    //             alt="EDM Drilling Machine"
    //           />
    //           <h1>EDM Drilling Machine</h1>
    //         </div>
    //       </button>
    //     </div>
    //     <div className="flex justify-evenly m-10">
    //       <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 w-96 h-96 cursor-default m-2 hover:scale-125">
    //         <div className="flex flex-col items-center font-bold">
    //           <img
    //             className="w-80 h-80"
    //             src={FLCM}
    //             alt="Fiber Laser Cutting Machine"
    //           />
    //           <h1>Fiber Laser Cutting Machine</h1>
    //         </div>
    //       </button>
    //     </div>
    //   </div>
    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Machines</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
            <img
              className="w-80 h-80"
              src={HPVCWM8KW}
              alt="Hydraulic PVC welding machine 8KW"
            />
            <h1>Hydraulic PVC welding machine 8KW</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={PPVCWM8KW}
              alt="Penumatic PVC welding machine 8KW"
            />
            <h1>Penumatic PVC welding machine 8KW</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={LMM20W}
              alt="Laser marking Machine 20W"
            />
            <h1>Laser marking Machine 20W</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={CNCEM}
              alt="CNC Engraving Machine"
            />
            <h1>CNC Engraving Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={OEM} alt="Oil Extraction Machine" />
            <h1>Oil Extraction Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={LEM30W}
              alt="Laser Engraving Machine 30W"
            />
            <h1>Laser Engraving Machine 30W</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={CNCR2_5WK} alt="CNC Router 5.2KW" />
            <h1>CNC Router 5.2KW</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={EDMDM} alt="EDM Drilling Machine" />
            <h1>EDM Drilling Machine</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={FLCM}
              alt="Fiber Laser Cutting Machine"
            />
            <h1>Fiber Laser Cutting Machine</h1>
          </div>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default MachineryPage;
