import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU836FIMG from "../../../assets/Ceramics Triode(5-350KW)/FU-836F/FU-836F.jpg";
import DI from "../../../assets/Ceramics Triode(5-350KW)/FU-836F/img1.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU-836F/img2.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU-836F/img3.jpg";
import Purchase from "../../../components/Purchase";

const CTFU836F = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU836FIMG} alt="" />
            <h2 className="text-xl">FU-836F Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-836F is a direct thermal carbonization thorium-tungsten
              cathode,the matel-ceramic triode,the anode cooling is forced
              air,the maximum anode dissipation power is 35KW,the maximum
              operating frequency is 60MHz,the output power is 60KW.the tube is
              mainly used for industrial high frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU836F;
