import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU958SIMG from "../../../assets/Ceramics Triode(5-350KW)/FU958S/FU958S.jpg";
import DI from "../../../assets/Ceramics Triode(5-350KW)/FU958S/img1.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FU958S/img2.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FU958S/img3.jpg";
import Purchase from "../../../components/Purchase";

const CTFU958S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU958SIMG} alt="" />
            <h2 className="text-xl">FU958S Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              Type tube FU958S is a thorium-tungsten cathode of the direct
              thermal carbonization,matel-ceramic triode,for water-cooled anode
              cooling,anode dissipation power 40KW.maximum operating frequency
              is of 30MHz,the pipes are mainly used in industrial high frequency
              heating equipment..Can be used interchangeably with Siemens
              RS3060CJ type tube.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU958S;
