import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GTFU307SIMG from "../../../assets/Glass Triode(1-30KW)/FU-307S/FU-307S.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/FU-307S/img1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/FU-307S/img2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/FU-307S/img3.jpg";
import Purchase from "../../../components/Purchase";

const GTFU307S = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GTFU307SIMG} alt="" />
            <h2 className="text-xl">FU-307S Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU-307S is a direct thermal carbonization thorium-tungsten
              cathode,metallic glass transistor,for water-cooled anode,the
              maximum anode dissipation power is 30KW,the maximum operating
              frequency is 30MHz,the output power up to 30KW,the pipe used
              mainly for industrial in the frequency heating equipment.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GTFU307S;
