import React from "react";
import { Link } from "react-router-dom";
import SampleNavbar from "../../components/SampleNavbar";
import Footer from "../../components/Footer.jsx";
import Purchase from "../../components/Purchase.jsx";
import AVT211 from "../../assets/Audio vacuum tube/211.jpg";
import AVT212 from "../../assets/Audio vacuum tube/212.jpg";
import AVT845 from "../../assets/Audio vacuum tube/845.jpg";

const AudioVacuumTube5 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Audio Vacuum Tube</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <Link to="/AVT212-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={AVT212} alt="212" />
                <h1>212</h1>
              </div>
            </button>
          </Link>
          <Link to="/AVT211-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={AVT211} alt="211" />
                <h1>211</h1>
              </div>
            </button>
          </Link>
          <Link to="/AVT845-Page">
            <button class="shadow-lg shadow-gray-300 hover:shadow-blue-900 hover:scale-125 m-10">
              <div className="flex flex-col items-center font-bold">
                <img className="w-60 h-60" src={AVT845} alt="845" />
                <h1>845</h1>
              </div>
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default AudioVacuumTube5;
