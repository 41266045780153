import React from "react";
import LG from "../assets/jtclogo.png";
import QR from "../assets/qrcode.png";
// import "./Footercss.css";
export default function Footer() {
  return (
    // <>
    //   <footer id="contact" className="bg-gray-900 text-white overflow-hidden">
    //     <div className="footerBox flex flex-row p-10  ">
    //       <div className="commondiv qrcode flex flex-col items-center justify-center ">
    //         <span className="text-2xl mb-5">
    //           <i class="fa-solid fa-circle"></i> Let's Connect
    //         </span>
    //         <img className="w-36 h-36" src={QR} alt="" />
    //       </div>
    //       <div className="commondiv address flex flex-col items-center justify-center text-sm ">
    //         <span className="font-semibold">Jayant Trading Company</span>
    //         <span className="text-center">
    //           13/31, BAWA PLAZA, SHOP NO. 1, FIRST FLOOR, ARYA SAMAJ ROAD, WEA,
    //           KAROL BAGH, OPP GAFFAR MARKET, New Delhi, Delhi, India, 110005
    //         </span>
    //         <br />
    //         <span className="">+91-9654333367</span>
    //         <br />
    //         <span>jtcindia@outlook.com</span>
    //       </div>
    //       <div class="main-block">
    //         <form action="/" method="post">
    //           <h1 className="flex justify-center font-semibold">Contact Us</h1>
    //           <div class="info">
    //             <input
    //               class="fname bg-slate-300 m-2 text-black rounded-md"
    //               type="text"
    //               name="name"
    //               placeholder="Full name"
    //             />
    //             <input
    //               class="bg-slate-300 m-2 text-black rounded-md"
    //               type="text"
    //               name="name"
    //               placeholder="Email"
    //             />
    //             <input
    //               class="bg-slate-300 m-2 text-black rounded-md"
    //               type="text"
    //               name="name"
    //               placeholder="Phone number"
    //             />
    //           </div>
    //           <p className="pl-2">Message</p>
    //           <div>
    //             <textarea
    //               class="bg-slate-300 m-2 text-black rounded-md"
    //               rows="4"
    //             ></textarea>
    //           </div>
    //           <button
    //             className="font-semibold pl-2 hover:text-cyan-500"
    //             type="submit"
    //             href="/"
    //           >
    //             SUBMIT
    //           </button>
    //         </form>
    //       </div>
    //       <div
    //         className="commondiv
    //       socialmedia flex flex-col items-center justify-center  "
    //       >
    //         <a
    //           target="_blank"
    //           className="text-2xl socialIcon mb-5 "
    //           href="https://www.instagram.com/jtcindia.in/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
    //         >
    //           <i class="fa-brands fa-instagram hover:text-cyan-500"></i>
    //         </a>
    //         <a
    //           target="_blank"
    //           className="text-2xl socialIcon mb-5 "
    //           href="https://www.facebook.com/JTCINDIA.IN"
    //         >
    //           <i class="fa-brands fa-facebook-f hover:text-cyan-500"></i>
    //         </a>
    //         <a
    //           target="_blank"
    //           className="text-2xl socialIcon mb-5 "
    //           href="https://wa.me/+919654333367"
    //         >
    //           <i class="fa-brands fa-whatsapp hover:text-cyan-500"></i>
    //         </a>
    //         <a
    //           target="_blank"
    //           className="text-2xl socialIcon "
    //           href="https://www.linkedin.com/in/jtc-india-jayant-trading-company-395b83176/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    //         >
    //           <i class="fa-brands fa-linkedin-in hover:text-cyan-500"></i>
    //         </a>
    //       </div>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <img className="h-14 w-14" src={LG} alt="" />
    //       <span className="text-sm text-center">
    //         Copyright © 2024, JTC India. All Rights Reserved. Developed By:{" "}
    //         <a
    //           target="_blank"
    //           className="hover:text-cyan-500"
    //           href="https://www.linkedin.com/in/jayant-basetia-1a5507222/"
    //         >
    //           Jayant Basetia
    //         </a>
    //       </span>
    //     </div>
    //   </footer>
    // </>
    <>
      <footer id="contact" className="bg-gray-900 text-white">
        <div className="flex flex-col items-center p-5 lg:flex-row">
          <div className=" flex flex-col items-center text-xl lg:w-1/4">
            <span className="mb-3">
              <i class="fa-solid fa-circle"></i> Let's Connect
            </span>
            <img className="w-36 h-36" src={QR} alt="" />
          </div>
          <div className="flex flex-col items-center mt-5 mb-5 lg:w-1/4">
            <span className="font-semibold mb-2">Jayant Trading Company</span>
            <span className=" text-center mb-2">
              13/31, BAWA PLAZA, SHOP NO. 1, FIRST FLOOR, ARYA SAMAJ ROAD, WEA,
              KAROL BAGH, OPP GAFFAR MARKET, New Delhi, Delhi, India, 110005
            </span>
            <div className="mt-2 mb-2">
              <span className="mr-2">+91-1143025944</span>
              <span className="ml-2">+91-9773925681</span>
            </div>
            <div className="mt-2 mb-2">
              <span className="mr-2">corporate@jtcindia.in</span>
            </div>
          </div>
          <div className="m-5 lg:w-1/4">
            <form className="" action="/" method="post">
              <h1 className="flex justify-center font-semibold mb-5 text-xl">
                Contact Us
              </h1>
              <div className="">
                <input
                  className="fname bg-slate-300  text-black rounded-md w-11/12"
                  type="text"
                  name="name"
                  placeholder="Full name"
                />
                <input
                  className="bg-slate-300 mt-4 mb-4 text-black rounded-md w-11/12"
                  type="text"
                  name="name"
                  placeholder="Email"
                />
                <input
                  className="bg-slate-300 mb-4 text-black rounded-md w-11/12"
                  type="text"
                  name="name"
                  placeholder="Phone number"
                />
              </div>
              <p className="">Message</p>
              <div>
                <textarea
                  class="bg-slate-300 text-black rounded-md w-11/12"
                  rows="4"
                ></textarea>
              </div>
              <button
                className="font-semibold mt-2 hover:text-cyan-500"
                type="submit"
                href="/"
              >
                SUBMIT
              </button>
            </form>
          </div>
          <div className="flex flex-row lg:w-1/4 lg:flex-col lg:items-center">
            <a
              target="_blank"
              className="text-2xl socialIcon mr-3 lg:m-0"
              href="https://www.instagram.com/jtcindia.in/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
            >
              <i class="fa-brands fa-instagram hover:text-cyan-500"></i>
            </a>
            <a
              target="_blank"
              className="text-2xl socialIcon ml-2 mr-2 lg:m-0 lg:mt-5 lg:mb-5"
              href="https://www.facebook.com/JTCINDIA.IN"
            >
              <i class="fa-brands fa-facebook-f hover:text-cyan-500"></i>
            </a>
            <a
              target="_blank"
              className="text-2xl socialIcon ml-3 mr-2 lg:m-0 lg:mb-5"
              href="https://wa.me/+919654333367"
            >
              <i class="fa-brands fa-whatsapp hover:text-cyan-500"></i>
            </a>
            <a
              target="_blank"
              className="text-2xl socialIcon ml-3 lg:m-0"
              href="https://www.linkedin.com/in/jtc-india-jayant-trading-company-395b83176/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            >
              <i class="fa-brands fa-linkedin-in hover:text-cyan-500"></i>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img className="h-14 w-14" src={LG} alt="" />
          <span className="text-sm text-center">
            Copyright © 2024, JTC India. All Rights Reserved. Developed By:{" "}
            <a
              target="_blank"
              className="hover:text-cyan-500"
              href="https://www.linkedin.com/in/jayant-basetia-1a5507222/"
            >
              Jayant Basetia
            </a>
          </span>
        </div>
      </footer>
    </>
  );
}
