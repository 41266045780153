import React from "react";
import Footer from "../../components/Footer";
import Ca7 from "../../assets/capacitor/ca_watermark (1)_page-0007.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";

const Capacitor7 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Pot Power RF-Capacitors & Rim
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca7}
          alt="Pot Power RF-Capacitors & Rim"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor7;
