import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFD911SAIMG from "../../../assets/Ceramics Triode(5-350KW)/FD-911SA/FD-911SA.jpg";
import DI from "../../../assets/Ceramics Triode(5-350KW)/FD-911SA/img1.jpg";
import TB1 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SA/img2.jpg";
import TB2 from "../../../assets/Ceramics Triode(5-350KW)/FD-911SA/img3.jpg";
import Purchase from "../../../components/Purchase";

const CTFD911SA = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(5-350KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFD911SAIMG} alt="" />
            <h2 className="text-xl">FD-911SA Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FD-911SA is a direct thermal carbonization thorium-tungsten
              cathode,the metal-ceramic triode,for water-cooled anode
              cooling,the maximum anode dissipation power is 80KW,maximum
              operating frequency is 26MHz,the output power is 100KW.the tube is
              mainly used for industrial high frequency heating device.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFD911SA;
