import React from "react";
import MainSlider from "../components/MainSlider.jsx";
import Footer from "../components/Footer.jsx";
import SampleNavbar from "../components/SampleNavbar.jsx";
import Purchase from "../components/Purchase.jsx";
export default function Home() {
  return (
    <>
      <main id="homePage">
        <SampleNavbar />
        <MainSlider />
        <Purchase />
        <Footer />
      </main>
    </>
  );
}
