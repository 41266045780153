import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import PKT88IMG from "../../../assets/Pentode/Pentode/KT88/KT88.jpg";
import DI from "../../../assets/Pentode/Pentode/KT88/img1.jpg";
import TB1 from "../../../assets/Pentode/Pentode/KT88/img2.jpg";
import TB2 from "../../../assets/Pentode/Pentode/KT88/img3.jpg";
import Purchase from "../../../components/Purchase";
const PKT88 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Pentode</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={PKT88IMG} alt="" />
            <h2 className="text-xl">KT88 Pentode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              Beam power tube KT88's anticathode limit dissipation power is 42W.
              In audio frequency amplifiler,if two tubes are used to drive
              AB1,the output can reach 100W,at the same time ,KT88 can also be
              used in electron firm press electron firm press electrial
              route,KT88 is the same with CV5220/6550,and can be exchanged each
              other. Heater hearing UH----6.3V 1H----1.6A
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default PKT88;
