import React from "react";
import Footer from "../components/Footer";
import Purchase from "../components/Purchase.jsx";
import Img1 from "../assets/Battries/14500_500mah.jpg";
import Img2 from "../assets/Battries/18650_1200mah.jpg";
import Img3 from "../assets/Battries/18650_1800mah.jpg";
import Img4 from "../assets/Battries/18650_2000mah.jpg";
import Img5 from "../assets/Battries/32700_600mah.jpg";
import Img6 from "../assets/Battries/Lithium_Battery.jpg";
import Img7 from "../assets/Battries/battery_pack.jpg";
import Img8 from "../assets/Battries/mobile_battery.jpg";
import SampleNavbar from "../components/SampleNavbar.jsx";

const Battery = () => {
  return (
    // <>
    //   <div>
    //     <SampleNavbar />
    //   </div>
    //   <div className="flex flex-col items-center justify-center content-center">
    //     <div className="flex items-center justify-center mt-5">
    //       <h1 className="text-3xl">Batteries</h1>
    //     </div>
    //     <div>
    //       <div className="flex justify-evenly m-10">
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img1}
    //               alt="LI-IONN(OOI) 14500 500MAH"
    //             />
    //             <h1>LI-IONN(OOI) 14500 500MAH</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img2}
    //               alt="LI-ION(OOI) 18650 1200MAH"
    //             />
    //             <h1>LI-ION(OOI) 18650 1200MAH</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img3}
    //               alt="LI-ION(OOI) 18650 1800MAH"
    //             />
    //             <h1>LI-ION(OOI) 18650 1800MAH</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img4}
    //               alt="LI-ION(OOI) 18650 2000MAH"
    //             />
    //             <h1>LI-ION(OOI) 18650 2000MAH</h1>
    //           </div>
    //         </button>
    //       </div>
    //       <div className="flex justify-evenly m-10">
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img5}
    //               alt="LFP Battery 32700 6000MAH"
    //             />
    //             <h1>LFP Battery 32700 6000MAH</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img
    //               className="w-80 h-80"
    //               src={Img6}
    //               alt="1260110 Polyer Battery 10000MAH"
    //             />
    //             <h1>1260110 Polyer Battery 10000MAH</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img src={Img7} alt="Battery Pack" />
    //             <h1>Battery Pack</h1>
    //           </div>
    //         </button>
    //         <button class="shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900 ">
    //           <div className="flex flex-col items-center font-bold">
    //             <img className="w-80 h-80" src={Img8} alt="Mobile Battery" />
    //             <h1>Mobile Battery</h1>
    //           </div>
    //         </button>
    //       </div>
    //     </div>
    //   </div>

    //   <div>
    //     <Purchase />
    //     <Footer />
    //   </div>
    // </>
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <div className="mb-5">
          <h1 className="text-3xl text-center">Batteries</h1>
        </div>
        <div className="flex flex-wrap justify-evenly">
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img1}
              alt="LI-IONN(OOI) 14500 500MAH"
            />
            <h1>LI-IONN(OOI) 14500 500MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img2}
              alt="LI-ION(OOI) 18650 1200MAH"
            />
            <h1>LI-ION(OOI) 18650 1200MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img3}
              alt="LI-ION(OOI) 18650 1800MAH"
            />
            <h1>LI-ION(OOI) 18650 1800MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img4}
              alt="LI-ION(OOI) 18650 2000MAH"
            />
            <h1>LI-ION(OOI) 18650 2000MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img5}
              alt="LFP Battery 32700 6000MAH"
            />
            <h1>LFP Battery 32700 6000MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img
              className="w-80 h-80"
              src={Img6}
              alt="1260110 Polyer Battery 10000MAH"
            />
            <h1>1260110 Polyer Battery 10000MAH</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img src={Img7} alt="Battery Pack" />
            <h1>Battery Pack</h1>
          </div>
          <div className="flex flex-col items-center font-bold shadow-lg shadow-gray-300 w-80 h-96 m-2 cursor-default hover:shadow-blue-900">
            <img className="w-80 h-80" src={Img8} alt="Mobile Battery" />
            <h1>Mobile Battery</h1>
          </div>
        </div>
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Battery;
