import React from "react";
import Footer from "../../components/Footer";
import Ca6 from "../../assets/capacitor/ca_watermark (1)_page-0006.jpg";
import SampleNavbar from "../../components/SampleNavbar";
import Purchase from "../../components/Purchase";

const Capacitor6 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
      </div>
      <div className="flex flex-col items-center m-5">
        <h1 className="text-3xl text-center mb-5">
          Bottle(Pot) Power RF-Capacitor(CCG11 & CCG10A)
        </h1>
        <img
          className="w-11/12 lg:w-1/2"
          src={Ca6}
          alt="Bottle(Pot) Power RF-Capacitor(CCG11 & CCG10A)"
        />
      </div>
      <div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default Capacitor6;
