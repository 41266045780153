import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import CTFU3069FFAIMG from "../../../assets/Ceramics(2.5-35KW)/FU3069FFA/FU3069FFA.jpg";
import DI1 from "../../../assets/Ceramics(2.5-35KW)/FU3069FFA/img1.jpg";
import DI2 from "../../../assets/Ceramics(2.5-35KW)/FU3069FFA/img2.jpg";
import TB1 from "../../../assets/Ceramics(2.5-35KW)/FU3069FFA/img3.jpg";
import TB2 from "../../../assets/Ceramics(2.5-35KW)/FU3069FFA/img4.jpg";
import Purchase from "../../../components/Purchase";

const CTFU3069FFA = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Ceramics Triode(2.5-35KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={CTFU3069FFAIMG} alt="" />
            <h2 className="text-xl">FU3069F/FA Ceramics Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              FU3069F/FA is a direct thermal carbonization thorium-tungsten
              cathode,the metal-ceramic triode,the anode cooling by forced
              air,the maximum anode dissipation power is 5KW(F),6KW(FA),the
              maximum operating frequency is 110MHz,the output power is 11KW.
              the pipes are mainly used in industrial high frequency heating
              equipment.E3069 can insstead E3069.
            </p>
            <br />
            <img className="w-96 h-96" src={DI1} alt="" />
            <br />
            <img className="w-96 h-96" src={DI2} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default CTFU3069FFA;
