import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import MCK2091IMG from "../../../assets/Magnetron/CK2091.jpg";
import TB1 from "../../../assets/Magnetron/img2.jpg";
import TB2 from "../../../assets/Magnetron/img3.jpg";
import TB3 from "../../../assets/Magnetron/img4.jpg";
import TB4 from "../../../assets/Magnetron/img5.jpg";
import Purchase from "../../../components/Purchase";

const MCK2091 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Magnetron</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={MCK2091IMG} alt="" />
            <h2 className="text-xl">CK2091 Magnetron</h2>
            <br />
            <p className="w-full lg:w-3/5">
              The CK2091 magnetron is an unpackaged, direct-heated magnetron
              with a pure tungsten cathode. The magnetic field is provided by an
              external electromagnet, the anode is cooled by water, the cathode
              lead-out end and the output end are air-cooled, and the microwave
              energy is output from the axial antenna. The tube is a fixed
              frequency continuous wave magnetron with a wavelength of 12 cm,
              which can directly excite a rectangular waveguide for microwave
              heating as a power source. It has the characteristics of high
              efficiency, long life, and high load stability.
            </p>
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB4} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default MCK2091;
