import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import GT5867AIMG from "../../../assets/Glass Triode(1-30KW)/5867A/5867A.jpg";
import DI from "../../../assets/Glass Triode(1-30KW)/5867A/IMG1.jpg";
import TB1 from "../../../assets/Glass Triode(1-30KW)/5867A/IMG2.jpg";
import TB2 from "../../../assets/Glass Triode(1-30KW)/5867A/img3.jpg";
import Purchase from "../../../components/Purchase";

const GT5867A = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Glass Triode(1-30KW)</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={GT5867AIMG} alt="" />
            <h2 className="text-xl">5867A Glass Triode</h2>
            <br />
            <p className="w-full lg:w-3/5">
              5867A is a direct thermal carbonization thorium-tungsten cathode
              glass triode anode cooling method is natural cooling,the maximum
              anode dissipation power is 350KW,the maximum operating frequency
              is 50MHz,the output power is 1.2KW.the tube is applicable in the
              field of industrial heating,power amplification or
              oscillation,also applies to the use of audio and RF power
              amplifier or modulator.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default GT5867A;
