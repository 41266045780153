import React from "react";
import SampleNavbar from "../../../components/SampleNavbar";
import Footer from "../../../components/Footer";
import AVT211IMG from "../../../assets/Audio vacuum tube/211/211.jpg";
import DI from "../../../assets/Audio vacuum tube/211/img1.jpg";
import TB1 from "../../../assets/Audio vacuum tube/211/img2.jpg";
import TB2 from "../../../assets/Audio vacuum tube/211/img3.jpg";
import TB3 from "../../../assets/Audio vacuum tube/211/img4.jpg";
import TB4 from "../../../assets/Audio vacuum tube/211/img5.jpg";
import Purchase from "../../../components/Purchase";

const AVT211 = () => {
  return (
    <>
      <div>
        <SampleNavbar />
        <div className="ml-5 mr-5 mb-10">
          <div className="flex flex-col items-center">
            <h1 className="text-3xl">Audio Vacuum Tube</h1>
            <br />
            <br />
            <img className="w-60 h-60" src={AVT211IMG} alt="" />
            <h2 className="text-xl">211 Audio Vacuum Tube</h2>
            <br />
            <p className="w-full lg:w-3/5">
              211 is straight heating thorium-tungsten cathode triodes.natural
              cooling,the anode maximum dissipation power is 100W,211 can be
              used tomodulator for low frequency power amplifier,oscillation and
              also can be being done in the rf oscillator amplifier.
            </p>
            <br />
            <img className="w-96 h-96" src={DI} alt="" />
            <br />
          </div>
          <div className="flex flex-col items-center">
            <img className="w-full lg:w-2/5" src={TB1} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB2} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB3} alt="" />
            <br />
            <img className="w-full lg:w-2/5" src={TB4} alt="" />
          </div>
        </div>
        <Purchase />
        <Footer />
      </div>
    </>
  );
};

export default AVT211;
